import React from "react";

import OurProducts from "../../components/OurProducts/OurProducts";

const PrototypeLab = () => {
  const labData = {
    mainTitle: "Prototype & Innovation Lab",
    labName: "Prototype & Innovation Lab",
    images: [
      require("../../assets/images/OurProducts/PrototypeLab/Prototype-Lab-1.webp"),
      require("../../assets/images/OurProducts/PrototypeLab/Prototype-Lab-2.webp"),
      require("../../assets/images/OurProducts/PrototypeLab/Prototype-Lab-3.webp"),
    ],
    aboutData: [
      <>
        Prototyping & Innovation Lab is a dedicated space where individuals have
        the opportunity to explore specialized knowledge and technology, turning
        ideas into real-life projects. Through our innovative hub, students
        embark on a journey of discovery, uncovering new knowledge and making
        significant advancements that shape the future of their fields.
      </>,
      require("../../assets/images/OurProducts/PrototypeLab/About-Prototype.webp"),
    ],
    benefits: [
      {
        img: require("../../assets/images/OurProducts/PrototypeLab/benefits-img-1.webp"),
        text: "Transforms Ideas into Reality",
      },
      {
        img: require("../../assets/images/OurProducts/PrototypeLab/benefits-img-2.webp"),
        text: "Ignites Innovation and Creativity",
      },
      {
        img: require("../../assets/images/OurProducts/PrototypeLab/benefits-img-3.webp"),
        text: "Strengthens Critical Thinking Skills",
      },
      {
        img: require("../../assets/images/OurProducts/PrototypeLab/benefits-img-4.webp"),
        text: "Empowers Visionary Initiatives",
      },
      {
        img: require("../../assets/images/OurProducts/PrototypeLab/benefits-img-5.webp"),
        text: "Encourages Exploration of Creativity",
      },
      {
        img: require("../../assets/images/OurProducts/PrototypeLab/benefits-img-6.webp"),
        text: "Comprehensive Robotic's Tools",
      },
    ],
    seo: {
      title: "PHN Technology | Prototyping & Innovation Lab",
      description:
        "Explore specialized knowledge and technology at PHN Prototyping & Innovation Lab, where students turn ideas into projects and shape the future of their fields.",
      hiddenTitle: "Prototyping & Innovation Lab",
      header: {
        mainTitle: "From Concept to Creation",
        subTitle: "Robotics Lab for Innovation & Unlimited Possibilities",
        className: "prototypeLab-common-header",
      },
    },
  };
  const breadcrumbData = [
    {
      title: "Home /",
      to: "/",
      status: "prev",
    },
    {
      title: "Prototype & Innovation Lab",
      to: "/prototype-and-innovation-lab",
      status: "current",
    },
  ];
  return (
    <>
      {/* all labs same structure component */}
      <OurProducts data={labData} breadcrumbData={breadcrumbData} />
    </>
  );
};

export default PrototypeLab;
