import React from "react";
import OurProducts from "../../components/OurProducts/OurProducts";

const ProductFoundationLab = () => {
  const labData = {
    mainTitle: "Foundation Lab",
    labName: "Foundation Lab",
    images: [
      require("../../assets/images/OurProducts/FoundationLab/Foundation-Lab-1.webp"),
      require("../../assets/images/OurProducts/FoundationLab/Foundation-Lab-2.webp"),
    ],
    aboutData: [
      <>
        In our Foundation Lab, our top priority is to provide advance equipment
        and engaging hands-on learning experience. We are committed in providing
        a broad range of Robotic tools, including 3D printers, tech project
        equipment and more, alongside advanced training session. Our commitment
        is to ensure that students gain the maximum benefit from their time with
        us.
      </>,
      require("../../assets/images/OurProducts/FoundationLab/About-Foundation.webp"),
    ],
    benefits: [
      {
        img: require("../../assets/images/OurProducts/FoundationLab/benefits-img-1.webp"),
        text: "Engaging Practical Learning",
      },
      {
        img: require("../../assets/images/OurProducts/FoundationLab/benefits-img-2.webp"),
        text: "Comprehensive Robotics Training",
      },
      {
        img: require("../../assets/images/OurProducts/FoundationLab/benefits-img-3.webp"),
        text: "Deeper Understanding of Technology",
      },
      {
        img: require("../../assets/images/OurProducts/FoundationLab/benefits-img-4.webp"),
        text: "Confidence in STEM Subjects",
      },
      {
        img: require("../../assets/images/OurProducts/FoundationLab/benefits-img-5.webp"),
        text: "Preparation for Future Careers",
      },
      {
        img: require("../../assets/images/OurProducts/FoundationLab/benefits-img-6.webp"),
        text: "Customized Learning Experiences",
      },
    ],
    seo: {
      title: "PHN Technology | Foundation Lab",
      description:
        "In PHN Foundation Lab, students access 3D printers, robotics tools, and more. Our advanced equipment and hands-on training maximize learning experiences.",
      hiddenTitle: "Foundation Lab",
      header: {
        mainTitle: "Your Gateway to Robotics Future",
        subTitle: "Explore, Experiment, Excel",
        className: "foundationLab-common-header",
      },
    },
  };
  const breadcrumbData = [
    {
      title: "Home /",
      to: "/",
      status: "prev",
    },
    {
      title: "Foundation Lab",
      to: "/foundation-lab",
      status: "current",
    },
  ];
  return (
    <>
      {/* all labs same structure component */}
      <OurProducts data={labData} breadcrumbData={breadcrumbData} />
    </>
  );
};

export default ProductFoundationLab;
