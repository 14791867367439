import React from "react";
import "../assets/css/Awards.css";
import award1 from "../assets/images/Awards/International-Glory-Award.svg";
import award2 from "../assets/images/Awards/Robotics-Startup-Award.svg";
import award3 from "../assets/images/Awards/Udyog-Bhushan-Puraskar.svg";

const Awards = () => {
  const awardsData = [
    {
      img: award2,
      title: "International Robotics Startup Award 2023",
    },
    {
      img: award1,
      title: "International Glory Awards 2023",
    },
    {
      img: award3,
      title: "Maharashtra Udyog Bhushan Puraskar 2023",
    },
  ];
  return (
    <section className="awards-section common-section">
      <div className="container">
        <p className="horizontal-styled-span mt-lg-4 mb-1"></p>
        <p className="common-title-span">Awards</p>
        <div className="awards-row row">
          {awardsData?.map((data, ind) => {
            return (
              <div key={ind} className="col">
                <div className="card awards-card text-center">
                  <div>
                    <img
                      src={data.img}
                      alt={data.title}
                      className="w-lg-50 m-auto h-100"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <p className="award-title">{data.title}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Awards;
