import React from "react";
import CommonHeader from "../../components/CommonHeader";
import '../../assets/css/ContactCss/ContactUs.css'
const ContactHeroSection = () => {
  const breadcrumbData = [
    {
      title: "Home /",
      to: "/",
      status: "prev",
    },
    {
      title: "Get Free Demo",
      to: "/contact-us",
      status: "current",
    },
  ];

  return (
    <section className="contact-hero-section">
      {/* <CommonHeader
        breadcrumbData={breadcrumbData}
        mainTitle="Reach Out"
        subTitle="Together We Build the Future"
        classNameOfCommonHeader="contact-us-common-header"
      /> */}
    </section>
  );
};

export default ContactHeroSection;
