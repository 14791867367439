// App.js
import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import "../assets/css/Trial.css";

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  useEffect(() => {
    gsap.utils.toArray(".section").forEach((section) => {
      if (section.classList.contains("horizontal")) {
        const cardsWrap = section.querySelector(".section__cards");
        const oneCard = section.querySelector(".section__card");

        gsap.to(cardsWrap, {
          x: () => -(cardsWrap.scrollWidth - oneCard.offsetWidth),
          ease: "none",
          scrollTrigger: {
            trigger: section,
            start: "center center",
            end: () => "+=" + (cardsWrap.scrollWidth - oneCard.offsetWidth),
            scrub: true,
            pin: true,
            invalidateOnRefresh: true,
            anticipatePin: 1,
          },
        });
      } else {
        ScrollTrigger.create({
          markers: true,
          trigger: section,
          start: "top top",
          pin: true,
          anticipatePin: 1,
        });
      }
    });
  }, []);

  return (
    <div className="App">
      <section className="section --red">
        <div className="section__card">
          <h1 className="section__title">Section 1</h1>
        </div>
      </section>

      <section className="section">
        <div className="section__card">
          <h1 className="section__title">Section 2</h1>
        </div>
      </section>

      <section className="section horizontal">
        <div className="section__cards">
          <div className="section__card">
            <h1 className="section__title">Section 3a</h1>
          </div>
          <div className="section__card">
            <h1 className="section__title">Section 3b</h1>
          </div>
          <div className="section__card">
            <h1 className="section__title">Section 3c</h1>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="section__card">
          <h1 className="section__title">Section 4</h1>
        </div>
      </section>
    </div>
  );
};

export default App;
