import React from "react";
import "../../assets/css/Collaborations/Collaboration.css";
import WhyRoboticsLab from "../HomePages/WhyRoboticsLab";
import { useNavigate } from "react-router-dom";
const CollaborationsMainPage = () => {
  const navigate = useNavigate();
  const collaborationData = [
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "collaboration 1 ",
      desc: "In today's digital-centric world, grasping the concept of digital marketing is essential. But what exactly is digital marketing? Digital marketing is the strategic use of online channels and technologies to promote products. Digital marketing is the strategic use of online channels and technologies to promote products, ",
      date: "June 23, 2024",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "collaboration 2 ",
      desc: "In today's digital-centric world, grasping the concept of digital marketing is essential. But what exactly is digital marketing? Digital marketing is the strategic use of online channels and technologies to promote products. Digital marketing is the strategic use of online channels and technologies to promote products, ",
      date: "June 23, 2024",
    },

    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "collaboration 3 ",
      desc: "In today's digital-centric world, grasping the concept of digital marketing is essential. But what exactly is digital marketing? Digital marketing is the strategic use of online channels and technologies to promote products. Digital marketing is the strategic use of online channels and technologies to promote products, ",
      date: "June 23, 2024",
    },
  ];

  const navigateToNextPage = (title) => {
    const formattedName = title.replace(/ /g, "_");

    const collaborationsData = collaborationData.find(
      (element) => element.title === title
    );

    navigate(`/collaborations/${formattedName}`, {
      state: { collaborationsData },
    });
  };
  return (
    <section className="common-section mt-5">
      <div className="container mt-lg-3 mt-4">
        <p className="horizontal-styled-span mb-0"></p>
        <p className="common-title">
          Our <span className="common-title-span">Collaborations</span>
        </p>

        <>
          {collaborationData.map((data, ind) => {
            return ind % 2 == 0 ? (
              <div className="row mt-lg-4 mt-4">
                <div className="col-lg-6 col-md-6 col-12 my-auto ">
                  <img
                    src={data.img}
                    alt="collaboration-img"
                    className="img-fluid collaboration-img"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12 ">
                  <div className="card collaborationCard pt-4">
                    <p className="collaborationTitle">{data.title}</p>
                    <p className="collaborationDesc">{data.desc}</p>

                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <p className="collaborationDate">{data.date}</p>
                      </div>
                      <div className="col-lg-6 col-12">
                        <p
                          className="collaborationKnowMore"
                          onClick={() => navigateToNextPage(data.title)}
                        >
                          Know More
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row mt-lg-4 mt-4">
                <div className="col-lg-6 col-md-6 col-12 order-lg-1 order-2">
                  <div className="card collaborationCard pt-4">
                    <p className="collaborationTitle">{data.title}</p>
                    <p className="collaborationDesc">{data.desc}</p>

                    <div className="row ">
                      <div className="col-lg-6 col-12">
                        <p className="collaborationDate">{data.date}</p>
                      </div>
                      <div className="col-lg-6 col-12">
                        <p
                          className="collaborationKnowMore"
                          onClick={() => navigateToNextPage(data.title)}
                        >
                          Know More
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 order-lg-2 order-1 my-auto">
                  <img
                    src={data.img}
                    alt="collaboration-img"
                    className="img-fluid collaboration-img"
                  />
                </div>
              </div>
            );
          })}
        </>
      </div>
      {/* why robotics lab component */}
      <WhyRoboticsLab />
    </section>
  );
};

export default CollaborationsMainPage;
