import React from "react";
import "../../assets/css/HomeCss/WhyRoboticsLab.css";
import ButtonStructure from "../../components/ButtonStructure";

const WhyRoboticsLab = () => {
  return (
    <section className="common-section whyRoboticsLab-section">
      <div className="container">
        <p className="whyRoboticsLab-title">
          Would you like to set up a{" "}
          <span style={{ color: "#2F6FED", textTransform:'capitalise '}}>Robotics, Innovation & Entrepreneurship Center</span> in your
          school?
        </p>
        <div className="d-flex justify-content-center mt-lg-5 mt-1 mt-md-4 mt-sm-2" >
          <ButtonStructure
            btnName="Get Free Demo"
            NameOfClass="navbarContactBtn"
            to="/contact-us"
          />
        </div>
      </div>
    </section>
  );
};

export default WhyRoboticsLab;
