import React from "react";
import AboutHeroSection from "./AboutHeroSection";
import AboutVisionMissionStructure from "../../components/AboutUsComponents/AboutVisionMissionStructure";
import "../../assets/css/AboutCss/AboutUs.css";
import OurMilestone from "./OurMilestone";
import SchoolsAssociated from "../SchoolsAssociated";
import WhyRoboticsLab from "../HomePages/WhyRoboticsLab";
import Awards from "../Awards";
import { Helmet } from "react-helmet-async";
import AdvisoryCards from "./AdvisoryCards";
const AboutUs = () => {
  const aboutUsData = {
    content: [
      <>
        PHN strongly believes in providing tailored academic solutions for
        schools and educational institutions by offering advanced AI & Robotics
        Labs for K12 education . Over the past decade, we have established
        strong business connections that empower these organizations to thrive
        in the &nbsp; digital era of IoT, AI/ML, AR/VR, Drones, EVs, & Product
        Engineering . <br />
        <br />
        At our core, we're always exploring new ideas, sparking creativity, and
        uncovering breakthroughs with continuous innovation and research . Our
        solutions are not only adaptable to meet the evolving needs of schools
        but also incorporate advanced educational technology (EdTech) to enhance
        learning outcomes. We are dedicated to helping our clients minimize
        costs while supporting growth and success in the dynamic field of
        education.
      </>,
      require("../../assets/images/AboutUs/AboutUsPage/about-us-img.webp"),
    ],
    layout: "text-left",
  };

  const visionData = {
    content: [
      <>
        Our Goal is to eliminate the barriers of accessibility, awareness, and
        affordability in EdTech education, ensuring that every student in the
        country has the opportunity to engage in effective and productive
        learning . Our aim to reach every corner of the nation, revolutionizing
        the education landscape to foster a new era of innovative and
        development focused learning.
        <br />
        <br /> We empower students with knowledge and confidence for leadership
        , cultivating a generation of innovators dedicated to the country's
        progress and development like never before.
      </>,
      require("../../assets/images/AboutUs/AboutUsPage/about-us-vision-img.webp"),
    ],
    layout: "text-right",
  };

  const missionData = {
    content: [
      <>
        Our aim is to Transform learning through the power of EdTech, making
        impactful and accessible education a reality for all students.
        <br />
        <br /> By providing comprehensive technical education in areas like STEM
        and Robotics from an early age, we aim to equip students with
        exceptional skills and intelligence to lead and innovate , shaping a
        brighter future for themselves and the nation.
      </>,

      require("../../assets/images/AboutUs/AboutUsPage/about-us-mission-img.webp"),
    ],
    layout: "text-left",
  };

  return (
    <>
      {/* about us SEO */}
      <h1 hidden>Advanced AI & Robotics Lab</h1>
      <Helmet>
        <title>PHN Technology | About Us</title>
        <meta
          name="description"
          content="Leading Ed-Tech provider in India, specializing in school robotics labs. We believe every student should learn vital robotics skills for the future."
        />
      </Helmet>

      <section className="about-us-section">
        {/* about us hero section component */}
        <AboutHeroSection />

        {/* about us section */}
        <div className="about-us common-section pt-2">
          <div className="container">
            <p className="horizontal-styled-span mt-lg-4 mb-1"></p>

            <p className="common-title" style={{ fontWeight: 700 }}>
              About Us
            </p>
            <AboutVisionMissionStructure data={aboutUsData} />
          </div>
        </div>

        {/* vission section */}
        <div className="about-us common-section pt-2">
          <div className="container">
            <div className="row mb-0">
              <div className="col-lg-6 col-md-12 col-12"></div>
              <div className="col-lg-6 col-md-12 col-12">
                <p className="horizontal-styled-span mt-lg-4 mb-1"></p>
                <p className="common-title mb-0">
                  Our <span style={{ fontWeight: 700 }}>Vision</span>
                </p>
              </div>
            </div>

            <AboutVisionMissionStructure data={visionData} />
          </div>
        </div>

        {/* mission section */}

        <div className="about-us common-section pt-2">
          <div className="container">
            <p className="horizontal-styled-span mt-lg-4 mb-1"></p>

            <p className="common-title">
              Our <span style={{ fontWeight: 700 }}>Mission</span>
            </p>
            <AboutVisionMissionStructure data={missionData} />
          </div>
        </div>

        {/* quote section */}
        <div className="container quote-section">
          <div className="card pt-4">
            <div className="row">
              <div className="col-lg-5 col-md-12 col-12 ps-lg-5 text-center">
                <img
                  src={require("../../assets/images/AboutUs/AboutUsPage/pradip-narayankar-CEO.webp")}
                  alt="pradip-narayankar-CEO"
                  className="img-fluid CEO-img-desktop"
                  loading="lazy"
                />
                <img
                  src={require("../../assets/images/AboutUs/AboutUsPage/pradip-narayankar-CEO-Mob.webp")}
                  alt="pradip-narayankar-CEO"
                  className="img-fluid CEO-img-mobile m-auto"
                  loading="lazy"
                />
              </div>
              <div className="col-lg-7 col-md-12 col-12 my-auto pe-lg-5 p-lg-0 p-4">
                <img
                  src={require("../../assets/images/AboutUs/AboutUsPage/starting-quote.webp")}
                  alt="starting-quote"
                  width={25}
                  className="starting-quote"
                  loading="lazy"
                />
                <p className="director-thought">
                  PHN Technology has followed a path of continuous innovation
                  and growth, extending from web and app development to
                  revolutionizing the world of education technology through the
                  integration of AI and robotic learning. We recognize the
                  importance of skills in today's society and aim to make a
                  difference beyond technology. Our goal is to empower India by
                  sparking the interest of young people nationwide and Providing
                  advanced resources such as AI and robotics, IoT, AI/ML, and a
                  wide range of such advance technologies to young minds in
                  every corner of the nation. We prioritize giving every child
                  access to quality education because we believe each child
                  holds the key to shaping India's future.
                  <img
                    src={require("../../assets/images/AboutUs/AboutUsPage/ending-quote.webp")}
                    alt="ending-quote"
                    width={25}
                    className="mt-4 ms-3"
                  />
                </p>
                {/* <div style={{ float: "right" }} className="me-5">
                  <p className="director-name mb-2">Mr. Pradip Narayankar </p>
                  <p className="director-post">
                    CEO & Founder of PHN Technology
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* our milestone */}
        <div className="common-section">
          <OurMilestone />
        </div>
        <AdvisoryCards />
        {/* our clients component */}
        <SchoolsAssociated />
        {/* awards component */}
        <Awards />
        {/* why robotics lab component */}
        <WhyRoboticsLab />
      </section>
    </>
  );
};

export default AboutUs;
