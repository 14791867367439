import React from "react";
import CommonHeader from "../../components/CommonHeader";

const AdvisoryHeroSection = () => {
  const breadcrumbData = [
    {
      title: "Home /",
      to: "/",
      status: "prev",
    },
    {
      title: "Advisory Board",
      to: "/advisory-board",
      status: "current",
    },
  ];

  return (
    <section className="about-hero-section common-section">
      {/* <CommonHeader
        breadcrumbData={breadcrumbData}
        mainTitle="Commitment to Excellence"
        subTitle="PHN Technology’s Advisory Board Leading the Way in EdTech"
        classNameOfCommonHeader="advisory-common-header"
      /> */}
    </section>
  );
};

export default AdvisoryHeroSection;
