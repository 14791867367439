import React from "react";
import PropTypes from "prop-types";
import "../assets/css/ImageTextGrid.css";

const ImageTextGrid = ({
  items,
  columnsLargeScreen,
  columnsMediumScreen,
  columnSmallScreen,
  columnExtraSmallScreen,
  divCss,
}) => {
  return (
    <div className={`row row-cols-auto ${divCss ? divCss : ""}`}>
      {items.map((item, index) => (
        <div
          className={`col-lg-${columnsLargeScreen} image-col col-md-${columnsMediumScreen} col-sm-${columnSmallScreen} col-${columnExtraSmallScreen} mb-4 `}
          key={index}
        >
          <div className={`image-container `}>
            <div className="image-wrapper text-center">
              <img src={item?.image} alt={`image`} className="image" loading="lazy"/>
            </div>
            <div className="text-wrapper">
              {item?.title && <p className="title">{item?.title}</p>}
              {item?.subtitle && <p className="subtitle">{item?.subtitle}</p>}
              {item?.firstLogo && (
                <div className="item-logo-first-container">
                  <div className="item-logo-wrapper">
                    <img
                      src={item?.firstLogo}
                      alt="logo"
                      className="item-logo-one"
                      loading="lazy"
                    />
                  </div>
                  {item?.firstLogoDesc && (
                    <p className="firstLogo-desc">{item?.firstLogoDesc}</p>
                  )}
                  <div className="item-text-wrapper"></div>
                </div>
              )}
              {item?.secondLogo && (
                <div className="item-logo-second-container">
                  <div className="item-logo-wrapper">
                    <img
                      src={item?.secondLogo}
                      alt="logo"
                      loading="lazy"
                      className="item-logo-second"
                    />
                  </div>
                  {item?.secondLogoDesc && (
                    <p className="secondLogo-desc">{item?.secondLogoDesc}</p>
                  )}
                  <div className="item-text-wrapper"></div>
                </div>
              )}

              {item?.images && (
                <div className="multiple-images">
                  {item?.images?.map((data, index) => (
                    <img key={index} src={data.src} alt={data.alt} loading="lazy" />
                  ))}
                </div>
              )}

              {item?.desc && <p className="desc">{item?.desc}</p>}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

ImageTextGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      text: PropTypes.string,
    })
  ).isRequired,
  columnsLargeScreen: PropTypes.number,
};

ImageTextGrid.defaultProps = {
  columnsLargeScreen: 4,
  columnsMediumScreen: 4,
  columnSmallScreen: 6,
  columnExtraSmallScreen: 6,
  divCss: "",
};

export default ImageTextGrid;
