import React, { useState } from "react";
import "../../assets/css/ContactCss/FAQ.css";

const FAQ = () => {
  const accordionData = [
    {
      title: "What is AI & Robotics Lab?",
      desc: "An AI & Robotics lab is an educational initiative that focuses specifically on robotics within the context of STEM by providing a facility where students can explore learn about robotics coding AI/ML. The lab is designed to provide hands-on experience through practical application with latest educational trends as per National Education Policy (NEP) 2020.",
    },
    {
      title: "Why AI & Robotics Lab is important for students?",
      desc: "The AI & Robotics lab provides a dynamic platform where students develop strong IQ and essential skills such as problem-solving abilities and teamwork, boosting their confidence from a young age and making them future-ready for their careers.",
    },
    {
      title: "What does PHN Technology offer?",
      desc: "PHN Technology offers a range of comprehensive services including setting up of advanced AI and Robotics lab for elementary and K12 education where young minds learn, create, and become future Innovators.",
    },
    {
      title: "What sets PHN Technology apart in the field of education?",
      desc: "Our USPs shine with expert trainers, advanced R&D hubs, patented student projects, and immersive robotics experiences, ensuring exceptional support, innovative ideas, academic triumphs, and hands-on experience. ",
    },
    {
      title:
        "What advantages will my child gain from PHN Advance AI and Robotic lab from a young age?",
      desc: "Here at PHN labs children gets practical & project-based learning to enhance their analytical and cognitive skill. Robotics education is the excellent method to boost IQ levels. It enhances creativity, as children are motivated to use their own imagination to build projects.",
    },
    {
      title:
        "What types of mechanical tools are provided to children for robotics, and how is their safety ensured?",
      desc: "Creating a robot in a lab typically involves utilizing a variety of mechanical tools and equipment, such as sensors, batteries, motors, actuators, controllers, as well as structural components like frames and chassis for assembly and testing, all carefully selected for their child-friendly design and ensure safety through dedicated trainer supervision.",
    },
    {
      title: "If I want to add PHN labs to my school, what do I need?",
      desc: "Well, you are just a click away. From lab designing, planning and implementation to execution and management, we ensure every detail is taken care of with precision and expertise. Just give us call or text.",
    },
    {
      title: "Do you offer training for teachers and staff?",
      desc: "Yes, we provide Teacher Training Programme (TTP) to help teachers and staff to become familiar with lab's equipment and resources or we directly provide dedicated expert trainers which is a time and cost-effective solution.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="common-section">
      <div className="container">
        <p className="horizontal-styled-span mb-1"></p>
        <p className="common-title-span">FAQ's</p>
        <div className="accordion" id="accordionExample">
          {accordionData?.map((data, ind) => {
            const isOpen = ind === activeIndex;
            return (
              <div className="accordion-item mt-4" key={ind}>
                <p className={`accordian-header mb-0 ${isOpen?"item-focused":""}`} id={`heading${ind}`}>
                  <button
                    className={`accordion-button ${isOpen ? "" : "collapsed"}`}
                    type="button"
                    onClick={() => handleToggle(ind)}
                    aria-expanded={isOpen ? "true" : "false"}
                    aria-controls={`collapse${ind}`}
                  >
                    {data.title}
                  </button>
                </p>
                <div
                  id={`collapse${ind}`}
                  className={`accordion-collapse collapse ${
                    isOpen ? "show" : ""
                  }`}
                  aria-labelledby={`heading${ind}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">{data.desc}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
