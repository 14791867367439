import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/BreadCrumb.css";
const BreadCrumb = ({ breadcrumbData }) => {
  return (
    <div>
      {breadcrumbData?.map((data, ind) => {
        return (
          <Link
            to={data?.to}
            className={
              data.status === "current" ? "activeBreadCrumb" : "prevBreadCrumb"
            }
            key={ind}
          >
            {data?.title}
          </Link>
        );
      })}
    </div>
  );
};

export default BreadCrumb;
