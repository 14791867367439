import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/Footer.css";
import PhnWhiteLogo from "../assets/images/Hero-Section/Phn-White-Logo.svg";
import stemLogo from "../assets/images/Footer/stem_image.svg";
import makeIndia from "../assets/images/Footer/Make_In_India.svg";
import callIcon from "../assets/images/Footer/call-icon.svg";
import mailIcon from "../assets/images/Footer/mail-logo.svg";
import locationIcon from "../assets/images/Footer/location_icon.svg";
import linkdinLogo from "../assets/images/Footer/Linkdin_logo.svg";
import InstaLogo from "../assets/images/Footer/Instagram-Icon.svg";
import XLogo from "../assets/images/Footer/X-Icon.svg";
import FacebookLogo from "../assets/images/Footer/Facebook-Icon.svg";
import YoutubeLogo from "../assets/images/Footer/Youtube-Logo.svg";
import ThreadsLogo from "../assets/images/Footer/InstaThreadsLogo.svg";

const Footer = () => {
  const navigate = useNavigate();

  const redirectToFaq = () => {
    navigate("/contact-us#Faq");
  };

  return (
    <section className="footer-section">
      <div className="container">
        <div className="row footer-main-section">
          {/* before */}
          {/*  col-lg-3 col-md-8 col-sm-12 col-12*/}
          <div className="col-lg-3 col-md-12 col-sm-12 col-12 mb-lg-5 mb-sm-3 mb-2">
            <div className="row">
              <div className="col-lg-12 col-md-4 col-4 order-1">
                <div>
                  <Link to="/">
                    <img
                      src={PhnWhiteLogo}
                      alt="phn-logo"
                      className="footer-phn-logo"
                      width={120}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12 order-lg-2 order-3">
                <p className="footer-sub-text mt-lg-3">
                  India’s Most Trusted Destination For <br />
                  <span style={{ fontWeight: "900" }}>
                    AI & Robotics Education
                  </span>
                </p>
              </div>
              <div className="Stem-India-Img-Wrapper col-lg-12 col-md-8 col-8 order-lg-3 order-2 mt-lg-3">
                <img
                  src={stemLogo}
                  alt="stem"
                  className="stem-india-logo logo-1"
                  loading="lazy"
                />
                <img
                  src={makeIndia}
                  alt="makeinIndia"
                  className="stem-india-logo"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-6">
            <p className="footer-nav-heading">PHN Labs</p>
            <ul className="footer-list">
              <li>
                <Link to="/center-of-excellence-lab" className="footer-text">
                  Center of Excellence Lab
                </Link>
              </li>
              <li>
                <Link to="/entrepreneurship-lab" className="footer-text">
                  Entrepreneurship Lab
                </Link>
              </li>
              <li>
                <Link
                  to="/prototype-and-innovation-lab"
                  className="footer-text"
                >
                  Prototype & Innovation Lab
                </Link>
              </li>
              <li>
                <Link to="/foundation-lab" className="footer-text">
                  Foundation Lab
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-4 col-4 mt-md-3 mt-lg-0 ps-5">
            <p className="footer-nav-heading">Media</p>
            <ul className="footer-list">
              <li>
                <Link to="/gallery" className="footer-text">
                  {" "}
                  Gallery
                </Link>
              </li>
            </ul>
          </div>
          {/* before media section */}
          {/* col-lg-3 col-md-3 col-sm-4 col-4 mt-md-3 mt-lg-0 */}
          {/*  */}
          <div className="col-lg-2 col-md-4 col-sm-6 col-6 mt-md-3 mt-4 mt-lg-0">
            <p className="footer-nav-heading">Our Company</p>
            <ul className="footer-list">
              <li>
                <Link to="/about-us" className="footer-text">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/advisory-board" className="footer-text">
                  Advisory Board
                </Link>
              </li>
              <li>
                <Link to="/careers" className="footer-text">
                  Careers
                </Link>
              </li>
              <li>
                <span
                  onClick={redirectToFaq}
                  className="footer-text"
                  style={{ cursor: "pointer" }}
                >
                  FAQ
                </span>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-5 mt-3 mt-lg-0 mt-md-3">
            <p className="footer-nav-heading">Contact Info</p>
            <ul className="footer-list">
              <li className="footer-text row list-row">
                <div className="col-1">
                  <img
                    src={callIcon}
                    alt="call-icon"
                    className="contact-icons"
                    loading="lazy"
                  />
                </div>
                <div className="col-11">+91 9226056402</div>
              </li>
              <li className="footer-text row list-row">
                <div className="col-1">
                  <img
                    src={mailIcon}
                    alt="call-icon"
                    className="contact-icons"
                    loading="lazy"
                  />
                </div>
                <div className="col-11">info@phntechnology.com</div>
              </li>
              <li className="footer-text row list-row">
                <div className="col-1">
                  <img
                    src={locationIcon}
                    alt="call-icon"
                    className="contact-icons"
                    loading="lazy"
                  />
                </div>
                <div className="col-11">
                  Solitaire Business Hub E Wing 5010, F wing 5010 & 5020, 5th
                  Floor Viman Nagar, Pune, Maharashtra 411014
                </div>
              </li>
            </ul>
            <div
              className="d-flex justify-content-evenly"
              style={{ marginTop: "34px" }}
            >
              <Link
                to="https://www.linkedin.com/company/phn-technology/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkdinLogo} alt="linkedin-logo" />
              </Link>
              <Link
                to="https://www.instagram.com/phn_technology/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={InstaLogo} alt="instagram-logo" />
              </Link>
              <Link
                to="https://twitter.com/phntechno?lang=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={XLogo} alt="twitter-logo" />
              </Link>
              <Link
                to="https://www.facebook.com/phntechnology?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={FacebookLogo} alt="facebook-logo" />
              </Link>
              <Link
                to="https://www.threads.net/phn_technology"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ThreadsLogo} alt="threads-logo" />
              </Link>
              <Link
                to="https://www.youtube.com/channel/UCCoKsPs0lsyr383TIZOoRUg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={YoutubeLogo} alt="youtube-logo" />
              </Link>
            </div>
          </div>
        </div>
        <div className="row Copyright-wrapper footer-text">
          <div className="col-lg-7 col-md-12 col-sm-12 col-12">
            <p>
              © Copyright PHN Technology. All Rights Reserved. Designed by{" "}
              <span className="a_links">
                <Link to="/">PHN Technology</Link>
              </span>
            </p>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-12 privacy-cond-div row">
            <p className="a_links col-lg-6 col-md-6 col-sm-6 col-5">
              <Link to="/privacy-policy" target="_blank">
                Privacy Policy
              </Link>
            </p>
            <p className="a_links col-lg-6 col-md-6 col-sm-6 col-7">
              <Link to="/terms-conditions" target="_blank">
                Terms & Conditions
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
