import React, { useState, useEffect } from "react";
import "../../assets/css/AboutCss/AdvisoryBoard.css";
import advisoryBoardImg from "../../assets/images/AboutUs/Advisor/advisory-board-img.webp";
import AdvisoryHeroSection from "./AdvisoryHeroSection";
import WhyRoboticsLab from "../HomePages/WhyRoboticsLab";
import AdvisoryCards from "./AdvisoryCards";
import ShimmerEffect from "../../components/ShimmerEffect";
import { PhotoProvider, PhotoView } from "react-photo-view";

const AdvisoryBoard = () => {
  const [showShimmer, setShowShimmer] = useState(true);

  // This useEffect will run once after the component mounts and hide the shimmer after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowShimmer(false);
    }, 5000); // 5000 milliseconds = 5 seconds

    // Cleanup the timer if the component unmounts before the timer completes
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <section className="advisory-section">
        <AdvisoryHeroSection />
        <div className="container">
          <p className="horizontal-styled-span mb-0"></p>
          <p className="common-title mb-3">
            Advisory <span className="common-title-span">Board</span>
          </p>
          {showShimmer ? (
            <ShimmerEffect
              src={advisoryBoardImg}
              alt="advisoryBoardImg"
              shimmerHeight="400"
              shimmerWeight="1000"
              style={{ borderRadius: "24px" }}
            />
          ) : (
            <PhotoProvider>
              <div className="row">
                <PhotoView src={advisoryBoardImg}>
                  <img
                    src={advisoryBoardImg}
                    alt="gallery-images"
                    className="img-fluid"
                    style={{ borderRadius: "24px", cursor: "pointer" }}
                  />
                </PhotoView>
              </div>
            </PhotoProvider>
          )}
        </div>
        <AdvisoryCards />
        <WhyRoboticsLab />
      </section>
    </>
  );
};

export default AdvisoryBoard;
