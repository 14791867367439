import React from "react";
import SalesLogo from "../../assets/images/ContactUsCards/Sales-Logo.svg";
import CareersLogo from "../../assets/images/ContactUsCards/Careers-Logo.svg";
import SupportLogo from "../../assets/images/ContactUsCards/Support-Logo.svg";
import CallLogo from "../../assets/images/ContactUsCards/phone-icon.svg";
import MailIcon from "../../assets/images/ContactUsCards/mail-icon.svg";
import ImageTextGrid from "../../components/ImageTextGrid";
import "../../assets/css/ContactUsCards.css";
import ConnectUs from "../../assets/images/ContactUs/ConnectWithUs.webp";

const ContactUsCards = () => {
  const Data = [
    {
      id: 1,
      image: SalesLogo,
      title: "Sales",
      firstLogo: CallLogo,
      firstLogoDesc: "+91 9226056347",
      secondLogo: MailIcon,
      secondLogoDesc: "info@phntechnology.com",
    },
    {
      id: 2,
      image: CareersLogo,
      title: "Careers",
      firstLogo: CallLogo,
      firstLogoDesc: "+91 9226056387",
      secondLogo: MailIcon,
      secondLogoDesc: "hr@phntechnology.com",
    },
    {
      id: 3,
      image: SupportLogo,
      title: "Support",
      firstLogo: CallLogo,
      firstLogoDesc: "+91 9226056402",
      secondLogo: MailIcon,
      secondLogoDesc: "info@phntechnology.com",
    },
  ];

  return (
    <section className="contact-us-cards-section common-section">
      <div className="container">
        <p className="horizontal-styled-span mb-1"></p>
        <p className="common-title">
          Contact <span className="common-title-span">Us</span>
        </p>
        {/* <ImageTextGrid
          items={Data}
          columnsLargeScreen={4}
          columnExtraSmallScreen={12}
          columnSmallScreen={12}
          columnsMediumScreen={4}
          divCss="contactUSCards-imageGrid"
        /> */}
        <div className="row contactUSCards-imageGrid">
          <div className="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4 mb-lg-0 mb-md-0 mb-sm-3">
            {Data?.map((data) => (
              <div key={data.id} className="text-wrapper mb-2">
                <p className="title mb-2">{data.title}</p>
                <div className="item-logo-first-container">
                  <img src={data.firstLogo} alt="" className="item-logo-one" />
                  <p className="firstLogo-desc">{data.firstLogoDesc}</p>
                </div>
                <div className="item-logo-second-container">
                  <img
                    src={data.secondLogo}
                    alt=""
                    className="item-logo-second"
                  />
                  <p className="secondLogo-desc">{data.secondLogoDesc}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <img
              src={ConnectUs}
              alt="connectWithUs"
              className="connect-use-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsCards;
