import React from "react";
import { useNavigate } from "react-router-dom";
import cardData from "../../assets/json/BlogJson.json";

const BlogCards = ({ activeChip }) => {
  const navigate = useNavigate();
 
  const navigateToFullBlog = (blogName) => {
    const formattedBlogName = blogName.replace(/ /g, "_");

    const blogData = cardData[activeChip].find(
      (data) => data.blogTitle === blogName
    );

    navigate(`/blogs/${formattedBlogName}`, {
      state: { blogData, activeChip },
    });
  };

  return (
    <section>
      <div className="row">
        {cardData[activeChip].map((data, ind) => {
          return (
            <div
              className="col-lg-4 col-12 col-md-6 mt-5"
              key={ind}
              onClick={() => navigateToFullBlog(data.blogTitle)}
              style={{ cursor: "pointer" }}
            >
              <div className="card blogCard">
                <img src={data.img} alt="blog-img" className="blog-Img"/>
                <div className="blogCardData">
                  <span className="blogCategory">{activeChip}</span>
                  <p className="blogTitle">{data.blogTitle}</p>
                  <p className="blogDesc">{data.blogDesc}</p>
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <p className="blogAuthor mb-0">{data.blogAuthor}</p>
                      <p className="blogDate">{data.date}</p>
                    </div>
                    <div className="col-lg-6 col-12 my-auto">
                      <p className="blogReadingTime">{data.readingTime}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default BlogCards;
