// src/components/ImageDisplay.js
import React from "react";

const ImageDisplay = ({ src, alt }) => {
  return (
    <img
      src={src}
      alt={alt}
      className="img-fluid"
      style={{ width: "100%", height: "auto", borderRadius: "24px" }}
    />
  );
};

export default ImageDisplay;
