import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/PageNotFound.css";

const PageNotFound = () => {
  return (
    <section className="page-not-found-section common-section">
      <div className="container d-flex justify-content-center align-items-center flex-column">
        <p> Page Not Found</p>
        <Link to="/" className="page-not-found-link">
          Go To Home Page
        </Link>
      </div>
    </section>
  );
};

export default PageNotFound;
