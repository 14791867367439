import React, { useState } from "react";
import SchoolsAssociated from "../SchoolsAssociated";
import WhatClientSay from "./WhatClientSay";
import FoundationLab from "./FoundationLab";
import ExperienceCenter from "./ExperienceCenter";
import OurReach from "../OurReach";
import HeroSection from "../../components/HomeComponents/HeroSection";
import WhatWeOffer from "./WhatWeOffer";
import WhyRoboticsLab from "./WhyRoboticsLab";
import Labs from "./Labs";
import { Helmet } from "react-helmet-async";
import Footer from "../../components/Footer";
import AdvisoryCards from "../AboutUsPages/AdvisoryCards";
import PartnershipAndCollaborations from "./PartnershipAndCollaborations";

const Home = () => {
  const [isHeroSectionLoaded, setIsHeroSectionLoaded] = useState(false);

  const handleHeroSectionLoad = () => {
    setIsHeroSectionLoaded(true);
  };

  return (
    <section>
      {/* Home section SEO */}
      <h1 hidden>
        India’s Most Trusted Destination for AI & Robotics Education
      </h1>
      <Helmet>
        <title>
          PHN Technology | Advanced AI, Robotics and Coding Education for
          Students
        </title>
        <meta
          name="description"
          content="PHN Technology: Transforming education with cutting-edge robotics, IoT labs, AI & ML training, drone technology, and AR/VR services. Specializing in setting up IIT-level labs like Center of Excellence, Entrepreneurship, Prototype & Innovation, and Foundation Labs for schools and colleges. Enhance your institution's tech capabilities with PHN's innovative solutions."
        />
      </Helmet>

      <HeroSection onLoad={handleHeroSectionLoad} />
      {isHeroSectionLoaded && (
        <>
          {/* Partnership and Collaboration Component */}
          <PartnershipAndCollaborations />
          {/* PHN Labs Component */}
          <Labs />
          {/* Foundation Labs Component */}
          <FoundationLab />
          {/* Experience Center Component */}
          <ExperienceCenter />
          {/* Our USP Component */}
          <WhatWeOffer />
          {/* Why Robotics Lab Component */}
          <WhyRoboticsLab />
          {/* Our Reach Component */}
          <OurReach />
          {/*Advisory Cards Carousel Component */}
          <AdvisoryCards />
          {/*Our Clients Component */}
          <SchoolsAssociated />
          {/*Clients videos Component */}
          <WhatClientSay />
          {/* <OurPartners /> */}
          <Footer />
        </>
      )}
    </section>
  );
};

export default Home;
