import React from "react";
import { Link } from "react-router-dom";

const ButtonStructure = ({ to, btnName, NameOfClass, clickHandler, state }) => {
  return (
    <Link
      className={`btn ${NameOfClass ? NameOfClass : ""}`}
      to={to || ""}
      onClick={clickHandler || ""}
      state={state || ""}
    >
      {btnName || ""}
    </Link>
  );
};

export default ButtonStructure;
