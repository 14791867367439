import React, { useState, useEffect } from "react";
import ButtonStructure from "../../components/ButtonStructure";
import { Link } from "react-router-dom";
import stateJson from "../../assets/json/StateDistrict.json";
import { ToastContainer, toast } from "react-toastify";

const CareerForm = ({ post }) => {
  const [inputData, setInputData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    address: "",
    state: "",
    city: "",
    district: "",
    qualification: "",
    passoutYear: "",
    experience: "",
    hearAbout: "",
    resume: "",
    termsAndCondition: false,
    other: "",
  });
  console.log("post", post);
  const [errors, setErrors] = useState({});
  const nameRegex = /^[A-Za-z' ]*$/;
  const mobileRegx = /^[6789][0-9]{9}$/;
  const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "firstName":
        if (!value) {
          error = "Please enter first name.";
        } else if (!nameRegex.test(value)) {
          error = "Please enter a valid first name.";
        }
        break;
      case "lastName":
        if (!value) {
          error = "Please enter last name.";
        } else if (!nameRegex.test(value)) {
          error = "Please enter a valid last name.";
        }
        break;
      case "email":
        if (!value) {
          error = "Please enter email.";
        } else if (!emailRegx.test(value)) {
          error = "Please enter a valid email.";
        }
        break;
      case "phoneNo":
        if (!value) {
          error = "Please enter mobile no.";
        } else if (!mobileRegx.test(value)) {
          error = "Please enter valid mobile no.";
        }
        break;
      case "address":
        if (!value) {
          error = "Please enter address.";
        }
        break;
      case "state":
        if (!value) {
          error = "Please select state.";
        }
        break;
      case "city":
        if (!value) {
          error = "Please select city.";
        }
        break;
      case "district":
        if (!value) {
          error = "Please select district.";
        }
        break;
      case "qualification":
        if (!value) {
          error = "Please select qualification.";
        }
        break;
      case "passoutYear":
        if (!value) {
          error = "Please select passout year.";
        }
        break;
      case "experience":
        if (!value) {
          error = "Please select experience.";
        }
        break;
      case "hearAbout":
        if (!value) {
          error = "Please select how did you hear about us.";
        }
        break;
      case "resume":
        if (!value) {
          error = "Please upload resume.";
        } else if (value.size >= 5242880) {
          error =
            "Your file is too large. Please upload a file smaller than 5 MB.";
        }
        break;
      case "termsAndCondition":
        if (!value) {
          error = "Please accept the terms and conditions.";
        }
        break;
      case "other":
        if (!value) {
          error = "Please enter qualification.";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const changeHandler = (e) => {
    const { name, value, type, files, checked } = e.target;
    const newValue =
      type === "checkbox" ? checked : type === "file" ? files[0] : value;

    // Prevent numbers in name fields
    if (
      (name === "firstName" || name === "lastName") &&
      !nameRegex.test(value)
    ) {
      return;
    }

    // Prevent alphabets in the mobile number field
    if (name === "phoneNo" && isNaN(value)) {
      return;
    }

    setInputData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: newValue,
      };
      validateField(name, newValue);
      return updatedData;
    });
  };
  const validateForm = () => {
    let valid = true;
    Object.keys(inputData).forEach((key) => {
      validateField(key, inputData[key]);
      if (errors[key]) {
        valid = false;
      }
    });

    return valid;
  };
  const submitForm = async (e) => {
    e.preventDefault();
    const API = "https://phntechnology.com/backend/api/jobApplicationSubmit";

    // Check form validation
    if (!validateForm()) {
      return;
    }

    if (
      !inputData.firstName ||
      !inputData.lastName ||
      !inputData.email ||
      !inputData.phoneNo ||
      !inputData.address ||
      !inputData.state ||
      !inputData.city ||
      !inputData.district ||
      !inputData.qualification ||
      (inputData.qualification === "Other" && !inputData.other) ||
      !inputData.passoutYear ||
      !inputData.experience ||
      !inputData.hearAbout ||
      !inputData.resume ||
      !inputData.termsAndCondition
    ) {
      console.error("Form data is incomplete");

      return;
    }

    const formData = new FormData();
    formData.append("ja_first_name", inputData.firstName);
    formData.append("ja_last_name", inputData.lastName);
    formData.append("ja_email", inputData.email);
    formData.append("ja_phone", inputData.phoneNo);
    formData.append("ja_current_address", inputData.address);
    formData.append("ja_state", inputData.state);
    formData.append("ja_city", inputData.city);
    formData.append("ja_district", inputData.district);
    formData.append(
      "ja_qualification",
      inputData.qualification === "Other"
        ? inputData.other
        : inputData.qualification
    );
    formData.append("ja_passout_year", inputData.passoutYear);
    formData.append("ja_experience", inputData.experience);
    formData.append("ja_source", inputData.hearAbout);
    formData.append("ja_resume_file", inputData.resume);
    formData.append("ja_job_title", post);
    console.log("formdata", formData);
    try {
      const response = await fetch(API, {
        method: "POST",
        body: formData,
      });
      let resJson = await response.json();

      if (response.ok) {
        if (resJson.message === "Job application submitted successfully.") {
          toast.success("Form Submitted Successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setInputData({
            firstName: "",
            lastName: "",
            email: "",
            phoneNo: "",
            address: "",
            state: "",
            city: "",
            district: "",
            qualification: "",
            passoutYear: "",
            experience: "",
            hearAbout: "",
            resume: "",
            termsAndCondition: false,
            other: "",
          });
          document.querySelector('input[name="resume"]').value = "";
          document.querySelector(
            'input[name="termsAndCondition"]'
          ).checked = false;
        } else {
          toast.error("Something went wrong, Please try again later", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      } else {
        toast.error("Something went wrong, Please try again later", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again later", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const selectedState = inputData.state;

  const stateData = stateJson.states.find(
    (state) => state.state === selectedState
  );

  return (
    <section className="career-form-section">
      <div className="card">
        <p className="job-desc-title">Apply Now </p>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <div className="row">
          <div className="col-lg-6 col-12">
            <label htmlFor="firstName">First Name</label>
            <input
              onChange={changeHandler}
              type="text"
              placeholder="First Name"
              className="form-control"
              name="firstName"
              value={inputData.firstName}
            />
            {errors.firstName && (
              <p className="error-msg mb-0">{errors.firstName}</p>
            )}
          </div>
          <div className="col-lg-6 col-12">
            <label htmlFor="lastName">Last Name</label>
            <input
              onChange={changeHandler}
              type="text"
              placeholder="Last Name"
              className="form-control"
              name="lastName"
              value={inputData.lastName}
            />
            {errors.lastName && (
              <p className="error-msg mb-0">{errors.lastName}</p>
            )}
          </div>
          <div className="col-lg-6 col-12">
            <label htmlFor="email">Email Address</label>
            <input
              onChange={changeHandler}
              type="text"
              placeholder="Email Address"
              className="form-control"
              name="email"
              value={inputData.email}
            />
            {errors.email && <p className="error-msg mb-0">{errors.email}</p>}
          </div>
          <div className="col-lg-6 col-12">
            <label htmlFor="phoneNo">Mobile Number</label>
            <input
              onChange={changeHandler}
              type="text"
              placeholder="Mobile Number"
              className="form-control"
              name="phoneNo"
              minLength={10}
              maxLength={10}
              value={inputData.phoneNo}
            />
            {errors.phoneNo && (
              <p className="error-msg mb-0">{errors.phoneNo}</p>
            )}
          </div>

          <div className="col-12">
            <label htmlFor="address">Current Address</label>
            <input
              onChange={changeHandler}
              type="text"
              placeholder="Current Address"
              className="form-control"
              name="address"
              value={inputData.address}
            />
            {errors.address && (
              <p className="error-msg mb-0">{errors.address}</p>
            )}
          </div>

          <div className="col-lg-6 col-12">
            <label htmlFor="state">Current State</label>
            <select
              className="form-control form-select"
              name="state"
              onChange={changeHandler}
              value={inputData.state}
            >
              <option value="">Current State</option>
              {stateJson.states.map((statesData, ind) => (
                <option value={statesData.state} key={ind}>
                  {statesData.state}
                </option>
              ))}
            </select>
            {errors.state && <p className="error-msg mb-0">{errors.state}</p>}
          </div>
          <div className="col-lg-6 col-12">
            <label htmlFor="district">Current District</label>
            <select
              className="form-control form-select"
              name="district"
              onChange={changeHandler}
              value={inputData.district}
            >
              <option value="">Current District</option>
              {stateData &&
                stateData.districts.map((data, ind) => (
                  <option value={data} key={ind}>
                    {data}
                  </option>
                ))}
            </select>
            {errors.district && (
              <p className="error-msg mb-0">{errors.district}</p>
            )}
          </div>
          <div className="col-lg-6 col-12">
            <label htmlFor="city">Current City</label>
            <input
              type="text"
              placeholder="City"
              value={inputData.city}
              className="form-control"
              name="city"
              onChange={changeHandler}
            />
            {errors.city && <p className="error-msg mb-0">{errors.city}</p>}
          </div>

          <div className="col-lg-6 col-12">
            <label htmlFor="qualification">Qualification</label>
            <select
              className="form-control form-select"
              name="qualification"
              onChange={changeHandler}
              value={inputData.qualification}
            >
              <option value="">Qualification</option>
              <option value="BE/BTech">BE/BTech</option>
              <option value="ME/MTech">ME/MTech</option>
              <option value="MBA">MBA</option>
              <option value="BBA">BBA</option>
              <option value="PGDM">PGDM</option>
              <option value="BCA">BCA</option>
              <option value="MCA">MCA</option>
              <option value="BA">BA</option>
              <option value="Diploma in Engineering">
                Diploma in Engineering
              </option>
              <option value="Other">Other</option>
            </select>
            {errors.qualification && (
              <p className="error-msg mb-0">{errors.qualification}</p>
            )}
          </div>
          {inputData.qualification === "Other" && (
            <>
              <div className="col-lg-6 col-12">
                <label htmlFor="Other">Enter Your Qualification</label>
                <input
                  type="text"
                  placeholder="Enter Your Qualification"
                  onChange={changeHandler}
                  className="form-control"
                  name="other"
                />
                {errors.other && (
                  <p className="error-msg mb-0">{errors.other}</p>
                )}
              </div>
            </>
          )}

          <div className="col-lg-6 col-12">
            <label htmlFor="passoutYear">Passout Year</label>
            <select
              className="form-control form-select"
              name="passoutYear"
              onChange={changeHandler}
              value={inputData.passoutYear}
            >
              <option value="">Passout Year</option>
              {(() => {
                const options = [];
                for (let year = 2024; year >= 2000; year--) {
                  options.push(
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                }
                return options;
              })()}
            </select>
            {errors.passoutYear && (
              <p className="error-msg mb-0">{errors.passoutYear}</p>
            )}
          </div>

          <div className="col-lg-6 col-12">
            <label htmlFor="experience">Select Experience</label>
            <select
              className="form-control form-select"
              name="experience"
              onChange={changeHandler}
              value={inputData.experience}
            >
              <option value="">Select Experience</option>
              <option value="0-1 years">0-1 years</option>
              <option value="1-2 years">1-2 years</option>
              <option value="2-3 years">2-3 years</option>
              <option value="3-4 years">3-4 years</option>
              <option value="4-5 years">4-5 years</option>
              <option value="5-6 years">5-6 years</option>
              <option value="6-7 years">6-7 years</option>
              <option value="7-8 years">7-8 years</option>
              <option value="8-9 years">8-9 years</option>
              <option value="More than 10 years">More than 10 years</option>
            </select>
            {errors.experience && (
              <p className="error-msg mb-0">{errors.experience}</p>
            )}
          </div>
          <div className="col-lg-6 col-12">
            <label htmlFor="hearAbout">How did you hear about us?</label>
            <select
              className="form-control form-select"
              name="hearAbout"
              onChange={changeHandler}
              value={inputData.hearAbout}
            >
              <option value="">How did you hear about us?</option>
              <option value="NewsPaper">NewsPaper</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="Instagram">Instagram</option>
              <option value="Facebook">Facebook</option>
              <option value="Youtube">Youtube</option>
              <option value="Internet">Internet</option>
              <option value="Friends/Relatives">Friends/Relatives</option>
              <option value="PHN Employee">PHN Employee</option>
            </select>
            {errors.hearAbout && (
              <p className="error-msg mb-0">{errors.hearAbout}</p>
            )}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <label className="upload-file-title">Upload Resume/CV</label>
            <input
              onChange={changeHandler}
              type="file"
              name="resume"
              accept=".pdf"
            />
            {errors.resume && <p className="error-msg mb-0">{errors.resume}</p>}
          </div>
        </div>

        <div className=" my-2">
          <div className="d-flex gap-2">
            <input
              onChange={changeHandler}
              type="checkbox"
              name="termsAndCondition"
              id="termsAndCondition"
            />
            <label htmlFor="termsAndCondition" className="checkbox-label">
              I agree to the{" "}
              <Link to="/terms-conditions" target="_blank">
                Terms & Conditions
              </Link>
            </label>
          </div>
          {errors.termsAndCondition && (
            <p className="error-msg mb-0">{errors.termsAndCondition}</p>
          )}
        </div>

        <ButtonStructure
          btnName="Submit"
          NameOfClass="career-form-submit-btn"
          clickHandler={submitForm}
        />
      </div>
    </section>
  );
};

export default CareerForm;

//old code
