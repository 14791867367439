import React from "react";
import CarouselHome from "../CarouselHome";

const HeroSection = ({ onLoad }) => {
  return (
    <section className="hero-section">
      {/* Hero section Carousel Component*/}
      <CarouselHome onLoad={onLoad} />
    </section>
  );
};

export default HeroSection;
