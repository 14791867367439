import React, { useState, useEffect, useRef } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

const ShimmerEffect = ({
  src,
  alt = "",
  shimmerHeight,
  shimmerWeight,
  nameOfImgClass = "img-fluid",
  imgWidth,
  imgHeight,
  style = {},
}) => {
  const [showShimmer, setShowShimmer] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowShimmer(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      ref={containerRef}
      className="shimmer-image-container"
      style={{ position: "relative" }}
    >
      {showShimmer ? (
        <ShimmerThumbnail
          className="shimmer-container"
          width={shimmerWeight}
          height={shimmerHeight}
        />
      ) : (
        <img
          src={src}
          alt={alt}
          className={nameOfImgClass}
          loading="lazy"
          style={{
            width: imgWidth || "auto",
            height: imgHeight || "auto",
            ...style,
          }}
        />
      )}
    </div>
  );
};

export default ShimmerEffect;
