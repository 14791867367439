import React, { useRef, useState } from "react";

const UpcomingInnovations = () => {
  const innovationData = [
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "Innovation 1",
      desc: "This multifaceted discipline encompasses various practices, from search engine optimization (SEO), which boosts.",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "Innovation 2",
      desc: "This multifaceted discipline encompasses various practices, from search engine optimization (SEO), which boosts.",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "Innovation 3",
      desc: "This multifaceted discipline encompasses various practices, from search engine optimization (SEO), which boosts.",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "Innovation 4",
      desc: "This multifaceted discipline encompasses various practices, from search engine optimization (SEO), which boosts.",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "Innovation 5",
      desc: "This multifaceted discipline encompasses various practices, from search engine optimization (SEO), which boosts.",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "Innovation 6",
      desc: "This multifaceted discipline encompasses various practices, from search engine optimization (SEO), which boosts.",
    },
  ];

  const scrollRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 1.5;
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };


  return (
    <section className="common-section">
      <div className="container">
        <p className="horizontal-styled-span mb-0"></p>
        <p className="common-title">
          Upcoming <span className="common-title-span">Innovations</span>
        </p>
        <div
          className="upInnovationCardwrapper"
          ref={scrollRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {innovationData.map((data, ind) => (
            <div className="card" key={ind}>
              <div className="card-content">
                <div className="text-content order-lg-1 order-2">
                  <p className="upInnovationTitle">{data.title}</p>
                  <p className="upInnovationDesc">{data.desc}</p>
                  <p className="comingSoonBtn">Coming Soon</p>
                </div>
                <div className="image-content order-lg-2 order-1">
                  <img
                    src={data.img}
                    alt={data.title}
                    className="img-fluid"
                    draggable={false}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UpcomingInnovations;
