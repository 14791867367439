import React from "react";
import OurProducts from "../../components/OurProducts/OurProducts";

const CoELab = () => {
  const labData = {
    mainTitle: "Center of Excellence Lab",
    labName: "Center of Excellence Lab",
    images: [
      require("../../assets/images/OurProducts/CoELab/CoE-Lab-1.webp"),
      require("../../assets/images/OurProducts/CoELab/CoE-Lab-2.webp"),
      require("../../assets/images/OurProducts/CoELab/CoE-Lab-3.webp"),
    ],
    aboutData: [
      "At the Center of Excellence Lab, we provide an exceptional platform for students to engage with advanced AI, Robotics, and Research & Development through comprehensive training programs and workshops.\n\nWe are committed to advancing research in Robotics, IoT, and AI/ML. Our CoE Lab is designed for both school and college students, where they Learn, Explore, and become future Innovators.",
      require("../../assets/images/OurProducts/CoELab/About-CoE-Lab-1.webp"),
    ],
    benefits: [
      {
        img: require("../../assets/images/OurProducts/CoELab/benefits-img-1.webp"),
        text: "Career Boosting through Practical Skill Development",
      },
      {
        img: require("../../assets/images/OurProducts/CoELab/benefits-img-2.webp"),
        text: "Enhanced Understanding through Experiential Learning",
      },
      {
        img: require("../../assets/images/OurProducts/CoELab/benefits-img-3.webp"),
        text: "Knowledge Expansion at the Research Advancement Hub",
      },
      {
        img: require("../../assets/images/OurProducts/CoELab/benefits-img-4.webp"),
        text: "Staying Ahead with our Future-Focused Curriculum",
      },
      {
        img: require("../../assets/images/OurProducts/CoELab/benefits-img-5.webp"),
        text: "Staying Updated with Exposure to Emerging Technologies",
      },
      {
        img: require("../../assets/images/OurProducts/CoELab/benefits-img-6.webp"),
        text: "Exposure to Industry Standards and Practices",
      },
    ],
    seo: {
      title: "PHN Technology | Future Ready AI & Robotics CoE Lab",
      description:
        "The COE Lab offers AI, Robotics, and R&D training for students. Learn, explore, and become a future innovator. Shape your tech journey with us!",
      hiddenTitle: "Future Ready AI & Robotics Lab",
      header: {
        mainTitle: "Future-Ready AI & Robotics Lab",
        subTitle: "Advanced Technology, Inspiring Minds",
        className: "coeLab-common-header",
      },
    },
  };

  const breadcrumbData = [
    {
      title: "Home /",
      to: "/",
      status: "prev",
    },
    {
      title: "Center of Excellence Lab",
      to: "/center-of-excellence-lab",
      status: "current",
    },
  ];
  return (
    <>
    {/* all labs same structure component */}
      <OurProducts data={labData} breadcrumbData={breadcrumbData} />
    </>
  );
};

export default CoELab;
