import React from "react";
import OurProducts from "../../components/OurProducts/OurProducts";

const EnterpreneurshipLab = () => {
  const labData = {
    mainTitle: "Entrepreneurship Lab",
    labName: "Entrepreneurship Lab",
    images: [
      require("../../assets/images/OurProducts/EnterpreneurshipLab/Enterpreneurship-Lab-1.webp"),
      require("../../assets/images/OurProducts/EnterpreneurshipLab/Enterpreneurship-Lab-2.webp"),
      require("../../assets/images/OurProducts/EnterpreneurshipLab/Enterpreneurship-Lab-3.webp"),
    ],
    aboutData: [
      <>
        Entrepreneurship Lab serves as a bridge between innovative ideas and
        practical skills, equipped with advanced AI & robotic equipment. The lab
        is designed to develop entrepreneurial mindsets, it provides aspiring
        young minds with the knowledge, resources, and support needed to launch
        successful ventures. The entrepreneurship lab provides individuals with
        the opportunity to make their entrepreneurial ideas a reality.
      </>,
      require("../../assets/images/OurProducts/EnterpreneurshipLab/About-Enterpreneurship-Lab.webp"),
    ],
    benefits: [
      {
        img: require("../../assets/images/OurProducts/EnterpreneurshipLab/benefits-img-1.webp"),
        text: "Providing World of Career Opportunities",
      },
      {
        img: require("../../assets/images/OurProducts/EnterpreneurshipLab/benefits-img-2.webp"),
        text: "Unleashing the Potential of Entrepreneurial Minds",
      },
      {
        img: require("../../assets/images/OurProducts/EnterpreneurshipLab/benefits-img-3.webp"),
        text: "Nurturing Success in the Startup Landscape",
      },
      {
        img: require("../../assets/images/OurProducts/EnterpreneurshipLab/benefits-img-4.webp"),
        text: "Sparking Leadership Qualities and Confidence",
      },
      {
        img: require("../../assets/images/OurProducts/EnterpreneurshipLab/benefits-img-5.webp"),
        text: "Shaping Innovative Ideas into successful Business",
      },
      {
        img: require("../../assets/images/OurProducts/EnterpreneurshipLab/benefits-img-6.webp"),
        text: "Networking & Industry Connections",
      },
    ],
    seo: {
      title: "PHN Technology | Future Ready Entrepreneurship Lab",
      description:
        "Develop your entrepreneurial mindset at the PHN Entrepreneurship Lab, where advanced AI & Robotics meet the knowledge and resources to turn ideas into reality",
      hiddenTitle: "Entrepreneurship Lab",
      header: {
        mainTitle: "Inspiring Future Leaders",
        subTitle: "with Entrepreneurial Mindset and Business Expertise",
        className: "enterpreneurshipLab-common-header",
      },
    },
  };
  const breadcrumbData = [
    {
      title: "Home /",
      to: "/",
      status: "prev",
    },
    {
      title: "Entrepreneurship Lab",
      to: "/entrepreneurship-lab",
      status: "current",
    },
  ];
  return (
    <>
      {/* all labs same structure component */}
      <OurProducts data={labData} breadcrumbData={breadcrumbData} />
    </>
  );
};

export default EnterpreneurshipLab;
