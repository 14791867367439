import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/Gallery/Gallery.css";
import { Helmet } from "react-helmet-async";
import CommonHeader from "../../components/CommonHeader";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { ShimmerThumbnail } from "react-shimmer-effects";

const Gallery = () => {
  const [imgUrl, setImgUrl] = useState([]);
  const [showShimmer, setShowShimmer] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowShimmer(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // images fetched from API
  useEffect(() => {
    const initialPaths = [];
    for (let i = 1; i <= 16; i++) {
      const imageUrl = `https://phntechnology-website.s3.ap-south-1.amazonaws.com/gallery-images/${
        i <= 9 ? "0" + i : i
      }.webp`;
      initialPaths.push(imageUrl);
    }
    setImgUrl(initialPaths);
  }, []);


  return (
    <section className="common-section gallery-section">

      {/* Gallery SEO */}
      <Helmet>
        <title>PHN Technology | Gallery</title>
        <meta
          name="description"
          content="Welcome to the PHN Technology Robotics Lab Gallery! This page showcases the amazing work that our students are doing in the robotics laboratory."
        />
      </Helmet>
{/* 
      <CommonHeader
        breadcrumbData={[
          { title: "Home /", to: "/", status: "prev" },
          { title: "Gallery", to: "/gallery", status: "current" },
        ]}
        mainTitle="Snapshots of Innovation in Education"
        subTitle=""
        classNameOfCommonHeader="gallery-common-header"
      /> */}

      <div className="container common-section">
        <p className="common-title-span">Gallery</p>

        <PhotoProvider>
          <div className="row">
            {imgUrl.map((data, index) => {
              return (
                <div key={index} className="col-lg-4 col-12 mt-4">
                  {showShimmer ? (
                    <ShimmerThumbnail
                      className="shimmer-container"
                      width="400"
                      height="300"
                    />
                  ) : (
                    <PhotoView src={data}>
                      <img
                        src={data}
                        alt="gallery-images"
                        className="img-fluid gallery-img"
                        style={{ borderRadius: "12px", cursor: "pointer" }}
                      />
                    </PhotoView>
                  )}
                </div>
              );
            })}
          </div>
        </PhotoProvider>
      </div>
    </section>
  );
};

export default Gallery;
