import React from "react";
import { useInView } from "react-intersection-observer";
import "../assets/css/OurReach.css";
import SchoolsConnectedLogo from "../assets/images/OurReach/SchoolsConnected-Logo.svg";
import StudentTrainerLogo from "../assets/images/OurReach/StudentTrained-Logo.svg";
import AwardsReceivedLogo from "../assets/images/OurReach/AwardsReceived-Logo.svg";
import ExpertTrainer from "../assets/images/OurReach/ExpertTrainer-Logo.svg";
import CountUp from "react-countup";

const OurReach = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const Items = [
    {
      id: 1,
      image: SchoolsConnectedLogo,
      count: 3000,
      suffix: "+",
      title: "Schools Connected",
      duration: 2,
    },
    {
      id: 2,
      image: StudentTrainerLogo,
      count: 2,
      suffix: " Lac+",
      title: "Students Trained",
      duration: 2,
    },
    {
      id: 3,
      image: AwardsReceivedLogo,
      count: 5,
      suffix: "+",
      title: "Awards Received",
      duration: 2,
    },
    {
      id: 4,
      image: ExpertTrainer,
      count: 300,
      suffix: "+",
      title: "Expert Trainers",
      duration: 2,
    },
  ];

  return (
    <section className="common-section our-reach-section" ref={ref}>
      <div className="container">
        <p className="horizontal-styled-span"></p>
        <p className="common-title mt-lg-3 mt-3 mb-2" style={{ color: "white" }}>
          Our <span className="common-title-span">Reach</span>
        </p>
        <div className="row">
          {Items.map((data) => (
            <div className="col-lg-3 col-md-3 col-sm-6 col-6" key={data.id}>
              <div className="d-flex justify-content-center">
                <img
                  src={data.image}
                  alt={data.title}
                  className="our-reach-image"
                  loading="lazy"
                />
              </div>
              <div>
                <p className="count-text mb-0">
                  {inView && (
                    <CountUp
                      end={data.count}
                      duration={data.duration}
                      suffix={data.suffix}
                    />
                  )}
                </p>
                <p className="our-reach-text">{data.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurReach;
