import React, { useState } from "react";
import CommonHeader from "../../components/CommonHeader";
import WhyRoboticsLab from "../HomePages/WhyRoboticsLab";
import "../../assets/css/ViewAllClients.css";
import ShimmerEffect from "../../components/ShimmerEffect";
import ButtonStructure from "../../components/ButtonStructure";

const ViewAllClients = () => {
  const [showAll, setShowAll] = useState(false);
  const breadcrumbData = [
    {
      title: "Home /",
      to: "/",
      status: "prev",
    },
    {
      title: "Our Clients",
      to: "/our-clients",
      status: "current",
    },
  ];
  const images = require.context("../../assets/images/AllClients", true);
  const allImages = images.keys();
  const visibleImages = showAll ? allImages : allImages.slice(0, 8);
  const handleViewAllClick = () => {
    setShowAll(true);
  };
  const handleViewLess = () => {
    setShowAll(false);
  };

  return (
    <>
      <section className="view-all-clients-section common-section">
        <CommonHeader
          breadcrumbData={breadcrumbData}
          mainTitle="Shaping the Future of Education Together"
          classNameOfCommonHeader="view-all-clients-common-header"
        />

        <div className="container common-section">
          <p className="horizontal-styled-span"></p>
          <p className="common-title">
            Our <span className="common-title-span">Clients</span>
            <div className="all-schools my-lg-5 my-5">
              {visibleImages?.map((imagePath, ind) => {
                const allImg = imagePath?.split("/").pop();
                return (
                  <div
                    className="card border-0"
                    key={ind}
                    style={{ background: "none" }}
                  >
                    <div className="allClients-wrapper">
                      <ShimmerEffect
                        src={images(imagePath)}
                        alt={`school-logo-${ind + 1}`}
                        shimmerHeight="100"
                        shimmerWeight="300"
                        nameOfImgClass="m-auto allClients-images"
                      />

                      <p className="schools-name">
                        {allImg.replace(".webp", "")}
                      </p>
                    </div>{" "}
                  </div>
                );
              })}
            </div>
            {!showAll ? (
              <div className="d-flex justify-content-center">
                <ButtonStructure
                  clickHandler={handleViewAllClick}
                  NameOfClass="btn-primary "
                  btnName="View More"
                />
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <p className="many-more-title">and many more...</p>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <ButtonStructure
                    clickHandler={handleViewLess}
                    NameOfClass="btn-primary "
                    btnName="View Less"
                  />
                </div>
              </>
            )}
          </p>
        </div>
      </section>

      {/* why robotics lab component */}
      <WhyRoboticsLab />
    </>
  );
};

export default ViewAllClients;
