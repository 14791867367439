import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Blogs/BlogsMainPage.css"; // Ensure you have the necessary CSS
import { useMediaQuery } from "@mui/material";

const NextCollaborations = ({ collaborationName }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:991px)");

  const collaborationData = [
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "collaboration 1",
      desc: "In today's digital-centric world, grasping the concept of digital marketing is essential. But what exactly is digital marketing? Digital marketing is the strategic use of online channels and technologies to promote products.",
      date: "June 23, 2024",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "collaboration 2",
      desc: "In today's digital-centric world, grasping the concept of digital marketing is essential. But what exactly is digital marketing? Digital marketing is the strategic use of online channels and technologies to promote products.",
      date: "June 23, 2024",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "collaboration 3",
      desc: "In today's digital-centric world, grasping the concept of digital marketing is essential. But what exactly is digital marketing? Digital marketing is the strategic use of online channels and technologies to promote products.",
      date: "June 23, 2024",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "collaboration 4",
      desc: "In today's digital-centric world, grasping the concept of digital marketing is essential. But what exactly is digital marketing? Digital marketing is the strategic use of online channels and technologies to promote products.",
      date: "June 23, 2024",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "collaboration 5",
      desc: "In today's digital-centric world, grasping the concept of digital marketing is essential. But what exactly is digital marketing? Digital marketing is the strategic use of online channels and technologies to promote products.",
      date: "June 23, 2024",
    },
  ];

  const filteredData = Array.isArray(collaborationData)
    ? collaborationData.filter((data) => collaborationName !== data.title)
    : [];

  const navigateToNextPage = (title) => {
    const formattedName = title.replace(/ /g, "_");
    const collaborationsData = collaborationData.find(
      (element) => element.title === title
    );

    navigate(`/collaborations/${formattedName}`, {
      state: { collaborationsData },
    });
  };

  const chunkedData = [];
  for (let i = 0; i < filteredData.length; i += 3) {
    chunkedData.push(filteredData.slice(i, i + 3));
  }

  useEffect(() => {
    const carouselElement = document.querySelector("#collaborationCarousel");
    if (carouselElement) {
      new window.bootstrap.Carousel(carouselElement, {
        interval: false,
      });
    }
  }, []);

  return (
    <section className="common-section">
      {/* code for mobile view */}

      {isMobile ? (
        <>
          <p className="horizontal-styled-span mb-0"></p>
          <p className="common-title">
            What to Read <span className="common-title-span">Next</span>{" "}
          </p>
          <div className="scrollCardsWrapper">
            {filteredData.map((data, ind) => (
              <div
                className="blogCard"
                key={ind}
                onClick={() => navigateToNextPage(data.title)}
                style={{ cursor: "pointer" }}
              >
                <img src={data.img} alt="blog-img" className="img-fluid" />
                <div className="blogCardData">
                  <p className="blogTitle">{data.title}</p>
                  <p className="blogDesc">{data.desc}</p>
                  <p className="collaborationKnowMore">Know More</p>
                </div>
              </div>
            ))}
          </div>{" "}
        </>
      ) : (
        <>
          {/* code for desktop view */}

          <div>
            <p className="horizontal-styled-span mb-0"></p>
            <p className="common-title mb-4">
              What to Read <span className="common-title-span">Next</span>{" "}
            </p>

            <div id="collaborationCarousel" className="carousel slide">
              <div className="carousel-inner">
                {chunkedData.map((dataChunk, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    key={index}
                  >
                    <div className="row">
                      {dataChunk.map((data, ind) => (
                        <div
                          className="col-lg-4 mt-4"
                          key={ind}
                          onClick={() => navigateToNextPage(data.title)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="card blogCard">
                            <img
                              src={data.img}
                              alt="blog-img"
                              className="blog-Img"
                            />
                            <div className="blogCardData">
                              <p className="blogTitle">{data.title}</p>
                              <p className="blogDesc">{data.desc}</p>
                              <p className="collaborationKnowMore">Know More</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {collaborationData.length > 3 && (
            <div className="blog-carousel-btns">
              <button
                className="carousel-control-prev innovationPrevBtn"
                type="button"
                data-bs-target="#collaborationCarousel"
                data-bs-slide="prev"
              >
                <img
                  src={require("../../assets/images/Innovation/prev-arrow.png")}
                  alt="prev-arrow"
                />
              </button>
              <button
                className="carousel-control-next innovationNextBtn"
                type="button"
                data-bs-target="#collaborationCarousel"
                data-bs-slide="next"
              >
                <img
                  src={require("../../assets/images/Innovation/next-arrow.png")}
                  alt="next-arrow"
                />
              </button>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default NextCollaborations;
