import React from "react";
import newsData from "../../assets/json/NewsJson.json";
import { useNavigate } from "react-router-dom";
const NewsMainPage = () => {
    const navigate = useNavigate()
  const navigateToFullBlog = (newsName) => {
    const formattedNewsName = newsName.replace(/ /g, "_");

    const newsCardsData = newsData.find(
      (data) => data.newsTitle === newsName
    );

    navigate(`/news/${formattedNewsName}`, {
      state: { newsCardsData },
    });
  };
  return (
    <section className="common-section mt-5 blogs-main-section">
      <div className="container mt-4">
        <p className="horizontal-styled-span mb-0"></p>
        <p className="common-title-span">News</p>

        <div className="row">
          {newsData.map((data, ind) => {
            return (
              <div
                className="col-lg-4 col-12 col-md-6 mt-5"
                key={ind}
                onClick={() => navigateToFullBlog(data.newsTitle)}
                style={{ cursor: "pointer" }}
              >
                <div className="card blogCard">
                  <img src={data.img} alt="blog-img" className="blog-Img" />
                  <div className="blogCardData">
                    {/* <span className="blogCategory">{activeChip}</span> */}
                    <p className="blogTitle">{data.newsTitle}</p>
                    <p className="blogDesc">{data.newsDesc}</p>
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <p className="blogAuthor mb-0">{data.newsAuthor}</p>
                        <p className="blogDate">{data.date}</p>
                      </div>
                      <div className="col-lg-6 col-12 my-auto">
                        <p className="blogReadingTime">{data.readingTime}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default NewsMainPage;
