import React from "react";
import { Link, useLocation } from "react-router-dom";
import WhyRoboticsLab from "../HomePages/WhyRoboticsLab";
import NextCollaborations from "./NextCollaborations";

const CollaborationsDetailsPage = () => {
  const location = useLocation();
  const collaborationsData = location.state.collaborationsData;
  return (
    <section className="common-section mt-5">
      <div className="container mt-4">
        <div className="d-flex">
          <Link
            to="/"
            className="prevBreadCrumb"
            style={{ color: "rgba(0, 0, 0, 1)" }}
          >
            Home /
          </Link>
          <Link
            to="/collaborations"
            className="activeBreadCrumb"
            style={{ color: "rgba(0, 0, 0, 1)" }}
          >
            Our Collaborations
          </Link>
        </div>

        <div className="mt-4">
          <p className="blogDate">{collaborationsData.date}</p>

          <img
            src={collaborationsData.img}
            alt="blog-img"
            className="img-fluid"
          />
          <p className="blogTitle mt-4">{collaborationsData.title}</p>
          <p className="blogDesc mt-4">{collaborationsData.desc}</p>
        </div>
        {/* what to next read collaboration component */}

        <NextCollaborations collaborationName={collaborationsData.title} />
      </div>
      {/* why robotics lab component */}
      <WhyRoboticsLab />
    </section>
  );
};

export default CollaborationsDetailsPage;
