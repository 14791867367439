import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Blogs/BlogsMainPage.css";
import { useMediaQuery } from "@mui/material";
const NextInnovation = ({ innovationName }) => {
  const InnovationData = [
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "Innovation 1",
      desc: "In today's digital-centric world, grasping the concept of digital marketing is essential. But what exactly is digital marketing? Digital marketing is the strategic use of online channels and technologies to promote products.",
      date: "June 23, 2024",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "Innovation 2",
      desc: "In today's digital-centric world, grasping the concept of digital marketing is essential. But what exactly is digital marketing? Digital marketing is the strategic use of online channels and technologies to promote products.",
      date: "June 23, 2024",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "Innovation 3",
      desc: "In today's digital-centric world, grasping the concept of digital marketing is essential. But what exactly is digital marketing? Digital marketing is the strategic use of online channels and technologies to promote products.",
      date: "June 23, 2024",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "Innovation 4",
      desc: "In today's digital-centric world, grasping the concept of digital marketing is essential. But what exactly is digital marketing? Digital marketing is the strategic use of online channels and technologies to promote products.",
      date: "June 23, 2024",
    },
    {
      img: require("../../assets/images/Collaborations/dummy.png"),
      title: "Innovation 5",
      desc: "In today's digital-centric world, grasping the concept of digital marketing is essential. But what exactly is digital marketing? Digital marketing is the strategic use of online channels and technologies to promote products.",
      date: "June 23, 2024",
    },
  ];
  const isMobile = useMediaQuery("(max-width:991px)");
  const navigate = useNavigate();

  const filteredData = Array.isArray(InnovationData)
    ? InnovationData.filter((data) => innovationName !== data.title)
    : [];

  const navigateToNextPage = (innName) => {
    const formattedName = innName.replace(/ /g, "_");

    const InnovativeData = InnovationData.find(
      (element) => element.title === innName
    );

    navigate(`/innovations/${formattedName}`, {
      state: { InnovativeData },
    });
  };

  const chunkedData = [];
  for (let i = 0; i < filteredData.length; i += 3) {
    chunkedData.push(filteredData.slice(i, i + 3));
  }
  useEffect(() => {
    const carouselElement = document.querySelector("#innovationCarousel");
    if (carouselElement) {
      const carouselInstance = new window.bootstrap.Carousel(carouselElement, {
        interval: false,
      });
    }
  }, []);

  return (
    <section className="common-section">
      {/* code for mobile view */}

      {isMobile ? (
        <>
          <p className="horizontal-styled-span mb-0"></p>
          <p className="common-title">
            What to Read <span className="common-title-span">Next</span>{" "}
          </p>
          <div className="scrollCardsWrapper">
            {filteredData.map((data, ind) => (
              <div
                className="blogCard"
                key={ind}
                onClick={() => navigateToNextPage(data.title)}
                style={{ cursor: "pointer" }}
              >
                <img src={data.img} alt="blog-img" className="img-fluid" />
                <div className="blogCardData">
                  <p className="blogTitle">{data.title}</p>
                  <p className="blogDesc">{data.desc}</p>
                  <p className="collaborationKnowMore">Know More</p>
                </div>
              </div>
            ))}
          </div>{" "}
        </>
      ) : (
        <>
          {/* code for desktop view */}

          <div>
            <p className="horizontal-styled-span mb-0"></p>
            <p className="common-title mb-4">
              What to Read <span className="common-title-span">Next</span>{" "}
            </p>

            <div id="innovationCarousel" className="carousel slide">
              <div className="carousel-inner">
                {chunkedData.map((dataChunk, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    key={index}
                  >
                    <div className="row">
                      {dataChunk.map((data, ind) => (
                        <div
                          className="col-lg-4 mt-4"
                          key={ind}
                          onClick={() => navigateToNextPage(data.title)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="card blogCard">
                            <img
                              src={data.img}
                              alt="blog-img"
                              className="blog-Img"
                            />
                            <div className="blogCardData">
                              <p className="blogTitle">{data.title}</p>
                              <p className="blogDesc">{data.desc}</p>
                              <p className="collaborationKnowMore">Know More</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {InnovationData.length > 3 && (
            <div className="blog-carousel-btns">
              <button
                className="carousel-control-prev innovationPrevBtn"
                type="button"
                data-bs-target="#innovationCarousel"
                data-bs-slide="prev"
              >
                <img
                  src={require("../../assets/images/Innovation/prev-arrow.png")}
                  alt=""
                />
              </button>
              <button
                className="carousel-control-next innovationNextBtn"
                type="button"
                data-bs-target="#innovationCarousel"
                data-bs-slide="next"
              >
                <img
                  src={require("../../assets/images/Innovation/next-arrow.png")}
                  alt=""
                />
              </button>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default NextInnovation;
