import React from "react";
import CommonHeader from "../../components/CommonHeader";
import "../../assets/css/Career/Career.css";
import LifeAtPHN from "./LifeAtPHN";
import WorkWithUs from "./WorkWithUs";
const CareerDetails = () => {
  const breadcrumbData = [
    {
      title: "Home /",
      to: "/",
      status: "prev",
    },
    {
      title: "Careers",
      to: "/career-details",
      status: "current",
    },
  ];
  return (
    <section className="common-section career-details-section">
      {/* <CommonHeader
        breadcrumbData={breadcrumbData}
        mainTitle="Elevate Your Career"
        subTitle="Join our Dynamic Team & be a part of something great"
        classNameOfCommonHeader="career-common-header"
      /> */}
      {/* <LifeAtPHN /> */}

      {/* work with us component */}
      <WorkWithUs />
    </section>
  );
};

export default CareerDetails;
