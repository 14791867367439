import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Projects from "./pages/Projects";
import OurPartners from "./pages/OurPartners";
import Home from "./pages/HomePages/Home";
import AboutUs from "./pages/AboutUsPages/AboutUs";
import Navbar from "./components/Navbar";
import ContactUs from "./pages/ContactUs/ContactUs";
import Footer from "./components/Footer";
import ViewAllClients from "./pages/ViewAllClients/ViewAllClients";
import WhatsappContact from "./components/WhatsappContact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import { useEffect, useState } from "react";
import Gallery from "./pages/Gallery/Gallery";
import PageNotFound from "./pages/PageNotFound";
import CareerDetails from "./pages/Careers/CareerDetails";
import CareerFormDetails from "./pages/Careers/CareerFormDetails";
import AdvisoryBoard from "./pages/AboutUsPages/AdvisoryBoard";
import CoELab from "./pages/OurProducts/CoELab";
import EnterpreneurshipLab from "./pages/OurProducts/EnterpreneurshipLab";
import PrototypeLab from "./pages/OurProducts/PrototypeLab";
import ProductFoundationLab from "./pages/OurProducts/ProductFoundationLab";
import Trial from "./pages/Trial";
import BlogsMainPage from "./pages/Blogs/BlogsMainPage";
import BlogDetails from "./pages/Blogs/BlogDetails";
import NewsMainPage from "./pages/News/NewsMainPage";
import NewsDetails from "./pages/News/NewsDetails";
import CollaborationsMainPage from "./pages/Collaborations/CollaborationsMainPage";
import CollaborationsDetailsPage from "./pages/Collaborations/CollaborationsDetailPage";
import InnovationsMainPage from "./pages/Innovations/InnovationNewPage";
import InnovationDetails from "./pages/Innovations/InnovationDetails";

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="App">
        {location.pathname !== "/privacy-policy" &&
          location.pathname !== "/terms-conditions" && (
            <>
              <Navbar />
              <WhatsappContact />
            </>
          )}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/our-partners" element={<OurPartners />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/our-clients" element={<ViewAllClients />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/careers" element={<CareerDetails />} />
          <Route path="/careers/:job-title" element={<CareerFormDetails />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/advisory-board" element={<AdvisoryBoard />} />
          <Route path="/center-of-excellence-lab" element={<CoELab />} />
          <Route
            path="/entrepreneurship-lab"
            element={<EnterpreneurshipLab />}
          />
          <Route
            path="/prototype-and-innovation-lab"
            element={<PrototypeLab />}
          />
          <Route path="/foundation-lab" element={<ProductFoundationLab />} />
          <Route path="/trial" element={<Trial />} />
          <Route path="/blogs" element={<BlogsMainPage />} />
          <Route path="/blogs/:url" element={<BlogDetails />} />
          <Route path="/news" element={<NewsMainPage />} />
          <Route path="/news/:url" element={<NewsDetails />} />
          <Route path="/collaborations" element={<CollaborationsMainPage />} />
          <Route
            path="/collaborations/:url"
            element={<CollaborationsDetailsPage />}
          />
          <Route path="/innovations" element={<InnovationsMainPage />} />
          <Route path="/innovations/:url" element={<InnovationDetails />} />
        </Routes>

        {location.pathname !== "/privacy-policy" &&
          location.pathname !== "/terms-conditions" &&
          location.pathname !== "/" && <Footer />}
      </div>
    </>
  );
}

export default App;
