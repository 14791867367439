import React, { useEffect, useState } from "react";
import searchIcon from "../../assets/images/Careers/Career/search-icon.svg";
import ButtonStructure from "../../components/ButtonStructure";
const WorkWithUs = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const data = [
    {
      title: "Business Development Manager",
      location: "Pune | Fulltime | Experience Required: 4-5 Years",
      description:
        "Lead and strategize business growth initiatives, with 5-6 years of experience in client acquisition and market expansion. Develop and execute strategies to drive revenue growth, with a focus on market analysis and competitor assessment.",
      responsibilities: [
        "Develop and implement sales and marketing strategies to achieve district-level sales targets and objectives.",
        "Provide regular reports and updates on sales activities, pipeline development, and revenue forecasts to senior management.",
        "Prepare and deliver compelling sales presentations, proposals, and pitches to prospective clients.",
        "Negotiate contracts, pricing, and terms of agreements with clients to secure new business and maximize profitability.",
      ],
      skills: [
        "Excellent communication, negotiation, and presentation skills.",
        "Ability to build and maintain relationships with clients, partners, and internal stakeholders.",
        "Results-oriented with a focus on achieving sales targets and driving revenue growth.",
        "Self-motivated, proactive, and able to work independently with minimal supervision.",
        "Willingness to travel within the assigned district and occasionally beyond as required.",
      ],
      qualification: "BBA/MBA",
    },
    {
      title: "Business Development Associate",
      location: "Pune | Fulltime | Experience Required: 1-3 Years",
      description:
        "Support business growth through lead generation, with 1-3 years of experience in sales or market research. Conduct market research to identify trends and opportunities, and assist in preparing proposals and presentations.",
      responsibilities: [
        "Promote PHN services in reputed private individual and group schools and generate revenue sales closures.",
        "Visit schools daily and pitch product, give demos.",
        "Setting up meetings with potential school clients and new customer acquisition.",
        "Meeting with Director and school management for presentation, negotiations, and signing agreements.",
        "Build the sales pipeline and achieve sales targets.",
        "Negotiate and finalize deals with schools.",
      ],
      skills: [
        "Should have experience in B2B Sales.",
        "Clear verbal and written communication skills.",
        "Proficiency in MS Office and Advanced Excel.",
        "Ability to build rapport with team & clients.",
        "Time management and decision-making skills.",
      ],
      qualification: "BBA",
    },
    {
      title: "Content Writer",
      location: "Pune | Fulltime | Experience Required: 1-3 Years",
      description:
        "The Ideal Candidate should have experience in content creation. Create engaging and informative content for websites, blogs, social media, and marketing materials. Research industry trends and topics to produce high-quality articles, whitepapers, and case studies.",
      responsibilities: [
        "Collaborate with cross-functional teams, including marketing, design, and product teams, to brainstorm ideas, develop content concepts, and execute integrated campaigns.",
        "Stay updated on industry trends, best practices, and emerging technologies in video production and content creation to continuously improve content quality and effectiveness.",
        "Analyze video performance metrics, including views, engagement, and retention rates, and use insights to optimize content strategies and improve campaign effectiveness.",
        "Manage content calendar and deadlines to ensure timely delivery of video content assets and align with marketing campaigns and initiatives.",
      ],
      skills: [
        "Excellent storytelling and visual communication skills with the ability to create compelling narratives through video content.",
        "Strong organizational and time management skills with the ability to manage multiple projects and deadlines effectively.",
        "Creative thinker with a passion for creating visually stunning and engaging content.",
        "Strong communication and collaboration skills with the ability to work cross-functionally in a fast-paced environment.",
      ],
      qualification: "BA in Mass Communication & Journalism",
    },
    {
      title: "Robotics Trainer",
      location: "Pune | Fulltime | Experience Required: 0-1 Year",
      description:
        "As a Robotics Trainer, you will train school students in robotics concepts and applications. Design and deliver training programs on robotics principles, including programming languages such as Python and C++, and hardware platforms like Arduino and Raspberry Pi. Develop curriculum materials, hands-on exercises, and assessments to facilitate learning and skill development.",
      responsibilities: [
        "Design and deliver engaging robotics workshops and training sessions for students of all ages and skill levels.",
        "Develop and adapt curriculum materials to meet the needs of diverse learners and educational settings.",
        "Provide guidance and support to students as they work on robotics projects, troubleshooting technical issues and fostering creativity and problem-solving skills.",
      ],
      skills: [
        "Strong technical skills and knowledge of robotics platforms, programming languages (e.g., Python, C++, Java), and hardware components (e.g., Arduino, Raspberry Pi).",
        "Excellent communication and interpersonal skills, with the ability to engage and inspire students from diverse backgrounds.",
        "Passion for robotics education and a commitment to promoting STEM literacy.",
      ],
      qualification:
        "BTech in Electronics & Telecommunication / BSc in Electronics",
    },
    {
      title: "Curriculum Developer",
      location: "Pune | Fulltime | Experience Required: 2-4 Years",
      description:
        "A Curriculum Developer is responsible for designing, creating, and implementing educational materials and programs. This role involves collaborating with subject matter experts, educators, and instructional designers to develop engaging and effective curriculum that meets educational objectives and learning outcomes.",
      responsibilities: [
        "Collaborate with subject matter experts to understand learning objectives and content requirements.",
        "Design and develop curriculum materials, including lesson plans, course outlines, learning activities, assessments, and instructional resources.",
        "Utilize instructional design principles and learning theory to create engaging and effective learning experiences.",
        "Incorporate technology and multimedia tools to enhance curriculum delivery and student engagement.",
        "Conduct research on educational best practices, trends, and methodologies to inform curriculum development.",
        "Review and revise curriculum based on feedback, evaluation data, and changes in educational standards.",
      ],
      skills: [
        "Proven experience in curriculum development, instructional design, or education-related roles.",
        "Strong knowledge of educational principles, learning theories, and curriculum design models.",
        "Proficiency in using educational technology tools, learning management systems, and multimedia resources.",
        "Excellent communication skills, both written and verbal.",
        "Attention to detail and organizational skills to manage multiple projects and deadlines.",
        "Creative thinking and problem-solving abilities to design innovative and engaging curriculum solutions.",
      ],
      qualification: "BA/B.Ed with experience in a relevant field",
    },
  ];

  useEffect(() => {
    const filtered = data.filter(
      (job) =>
        job?.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        job?.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
        job?.description.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchQuery]);

  return (
    <section className="common-section work-with-us-section">
      <div className="container">
        <p className="horizontal-styled-span mb-0"></p>
        <p className="common-title">
          Work <span className="common-title-span">With Us</span>
        </p>

        <div class="input-group mb-lg-3">
          <input
            type="text"
            className="form-control search-input career-search-field"
            placeholder="Search for jobs by title or keyword"
            aria-label="Search for jobs by title or keyword"
            aria-describedby="basic-addon2"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <span class="input-group-text" id="basic-addon2">
            <img src={searchIcon} alt="search-icon" style={{ width: "30px" }} />
          </span>
        </div>

        <div>
          {filteredData?.length > 0 ? (
            filteredData?.map((data, ind) => {
              return (
                <div className="card mt-5" key={ind}>
                  <div className="row">
                    <div className="col-lg-8 col-12 order-lg-1 order-1">
                      <p className="job-title">{data.title}</p>
                    </div>
                    <div className="col-lg-4 col-12 text-end order-lg-2 order-5">
                      <ButtonStructure
                        btnName="Apply"
                        to={`/careers/${data.title}`}
                        NameOfClass="apply-now-btn"
                        state={data}
                      />
                    </div>

                    <div className="col-12 order-lg-3 order-2">
                      <p className="location">{data.location}</p>
                    </div>
                    <div className="col-12 order-lg-4 order-3">
                      <p className="job-description">{data.description}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="no-data-msg">No data found</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default WorkWithUs;
