import React, { useEffect } from "react";
import "../assets/css/HomeCss/CarouselHome.css";
import ButtonStructure from "./ButtonStructure";
import StemLogo from "../assets/images/Hero-Section/Stem-Logo.svg";

const CarouselHome = ({ onLoad }) => {
  // Define the slide data
  const slides = [
    {
      id: 1,
      deskImg: require("../assets/images/Hero-Section/AI&Robotics-Slide-1.webp"),
      mobImg: require("../assets/images/Hero-Section/AI&Robotics-Slide-1-mob.webp"),
      alt: "Slide 1",
      img: "",
      subTitle: (
        <>
          India's Most Trusted
          <br className="br-tag-desk" /> Destination For
        </>
      ),
      mainTitle: (
        <>
          AI & Robotics
          <br className="br-tag-desk" /> Education
        </>
      ),
    },
    {
      id: 2,
      deskImg: require("../assets/images/Hero-Section/Collab-With-IIT-NIT-ITI-Belop.webp"),
      mobImg: require("../assets/images/Hero-Section/Collab-With-IIT-NIT-ITI-Belop-mob.webp"),
      alt: "Slide 2",
      img: require("../assets/images/Hero-Section/Collab-With-IIT-Jammu-Patna.webp"),
      subTitle: (
        <>
          PHN Technology Teams Up with{" "}
          <span className="carousel-sub-title" style={{ fontWeight: "900" }}>
            {" "}
            IIT Jammu and IIT Patna:
          </span>
        </>
      ),
      mainTitle: <>A New Era in Robotics Education Begins!</>,
    },
    {
      id: 3,
      deskImg: require("../assets/images/Hero-Section/Collab-With-IIT-NIT-ITI-Belop.webp"),
      mobImg: require("../assets/images/Hero-Section/Collab-With-IIT-NIT-ITI-Belop-mob.webp"),
      alt: "Slide 2",
      img: require("../assets/images/Hero-Section/Collab-With-IIT-Guwahati-Delhi.webp"),
      subTitle: (
        <>
          PHN Technology Partners with
          <span className="carousel-sub-title" style={{ fontWeight: "900" }}>
            {" "}
            IIT Guwahati and NIT Delhi:
          </span>
        </>
      ),
      mainTitle: <>Elevating Robotics Education to New Heights!</>,
    },

    {
      id: 4,
      deskImg: require("../assets/images/Hero-Section/Collab-With-IIT-NIT-ITI-Belop.webp"),
      mobImg: require("../assets/images/Hero-Section/Collab-With-IIT-NIT-ITI-Belop-mob.webp"),
      alt: "Slide 3",
      img: require("../assets/images/Hero-Section/Collab-With-ITI-Belop.webp"),
      moImg: require("../assets/images/Hero-Section/Collab-With-ITI-Belop-mo.png"),
      subTitle: (
        <>
          PHN Technology Joins Forces with
          <span className="carousel-sub-title" style={{ fontWeight: "900" }}>
            {" "}
            BELOP and ITI Ltd.:
          </span>
        </>
      ),
      mainTitle: <>Empowering Today to Shape Tomorrow! </>,
    },
  ];

  useEffect(() => {
    const images = document.querySelectorAll(".carousel-item img");
    let loadedImages = 0;

    const handleImageLoad = () => {
      loadedImages++;
      if (loadedImages === images.length) {
        if (onLoad) onLoad();
      }
    };

    images.forEach((image) => {
      if (image.complete) {
        handleImageLoad();
      } else {
        image.addEventListener("load", handleImageLoad);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener("load", handleImageLoad);
      });
    };
  }, [onLoad]);

  return (
    <div className="carousel-container">
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          {slides.map((slide, index) => (
            <button
              key={slide.id}
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : "false"}
              aria-label={`Slide ${slide.id}`}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {slides.map((slide, index) => (
            <div
              key={slide.id}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              <img
                src={slide.deskImg}
                className="carouselDeskViewImg w-100"
                alt={slide.alt}
              />
              <img
                src={slide.mobImg}
                className="carouselMobViewImg w-100"
                alt={slide.alt}
              />
              <div className="carousel-text">
                <div className="container carousel-container">
                  <div className="row">
                    <div className="col-lg-7 col-md-7 col-12">
                      <p className="carousel-sub-title">{slide.subTitle}</p>
                      <p className="carousel-main-title mb-4">
                        {slide.mainTitle}
                      </p>
                      <div className="contact-us-and-stem-logo-desk">
                        <div className="d-flex gap-4">
                          <div>
                            <ButtonStructure
                              btnName="Contact Us"
                              NameOfClass="carouselContactBtn"
                              to="/contact-us"
                            />
                          </div>
                          <img
                            src={StemLogo}
                            alt="StemLogo"
                            className="mt-lg-3 stem-logo"
                            loading="eager"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-12 m-auto">
                      {slide.img ? (
                        <>
                          <img
                            src={slide.img}
                            className="img-fluid slide-img"
                          />
                        </>
                      ) : (
                        <img
                          src={require("../assets/images/Hero-Section/Collab-With-IIT-Jammu-Patna.webp")}
                          style={{ visibility: "hidden" }}
                          className="img-fluid"
                        />
                      )}
                      <div className="contact-us-and-stem-logo-mob mt-2">
                        <div className="d-flex gap-4">
                          <div>
                            <ButtonStructure
                              btnName="Contact Us"
                              NameOfClass="carouselContactBtn"
                              to="/contact-us"
                            />
                          </div>
                          <img
                            src={StemLogo}
                            alt="StemLogo"
                            className="mt-lg-3 stem-logo"
                            loading="eager"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselHome;
