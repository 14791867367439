import React from "react";
import "../../assets/css/AboutCss/AboutHeroSection.css";
import CommonHeader from "../../components/CommonHeader";

const AboutHeroSection = () => {
  const breadcrumbData = [
    {
      title: "Home /",
      to: "/",
      status: "prev",
    },
    {
      title: "About us",
      to: "/about-us",
      status: "current",
    },
  ];

  return (
    <section className="about-hero-section common-section">
      {/* <CommonHeader
        breadcrumbData={breadcrumbData}
        mainTitle="Imagination to Innovation"
        subTitle="Inspiring Future Leaders with Advance AI & Robotics Lab"
        classNameOfCommonHeader="about-us-common-header"
      /> */}
    </section>
  );
};

export default AboutHeroSection;
