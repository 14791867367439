import React from "react";
import { Link } from "react-router-dom";
import WhatsAppImg from "../assets/images/Common-Img/Whats-App-Logo.svg";
import "../assets/css/WhatsappContact.css";
const WhatsappContact = () => {
  return (
    <section className="whatsapp-contact">
      <div className="whatsapp-bot desktop-wp">
        <Link
          to="https://web.whatsapp.com/send?phone=919226056347&text=Hi there! I'm interested in learning more about your AI and Robotics Labs for K12 education. Can you provide more details?"
          target="_blank"
        >
          <img src={WhatsAppImg} alt="WhatsAppImg" className="WhatsApp-Img" />
        </Link>
      </div>
      <div className="whatsapp-bot mobile-wp">
        <Link
          to="https://wa.me/919226056347/?text=Hi there! I'm interested in learning more about your AI and Robotics Labs for K12 education. Can you provide more details?"
          target="_blank"
        >
          <img
            src={WhatsAppImg}
            alt="WhatsAppImg"
            className="WhatsApp-Img-Mobile"
          />
        </Link>
      </div>
    </section>
  );
};

export default WhatsappContact;
