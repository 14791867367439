import React from "react";
import "../../assets/css/HomeCss/WhatWeOffer.css";
import FirstImg from "../../assets/images/WhatWeOffer/WhatWeOffer-1.svg";
import SecondImg from "../../assets/images/WhatWeOffer/WhatWeOffer-2.svg";
import ThirdImg from "../../assets/images/WhatWeOffer/WhatWeOffer-3.svg";
import FourthImg from "../../assets/images/WhatWeOffer/WhatWeOffer-4.svg";
import ImageTextGrid from "../../components/ImageTextGrid";

const WhatWeOffer = () => {
  const items = [
    {
      image: FirstImg,
      title: (
        <>
          STEM Certified <br />
          Trainers
        </>
      ),
      subtitle: "Industry expert trainers guide and support all student.",
    },
    {
      image: SecondImg,
      title: "R&D and Innovation Centers",
      subtitle:
        "Advance Research drives innovation in a dynamic problem-solving hub.",
    },
    {
      image: ThirdImg,
      title: (
        <>
          Patent-Level <br /> Projects
        </>
      ),
      subtitle: "Student ideas to patents, boost academic and career wins.",
    },
    {
      image: FourthImg,
      title: "100% Hands-On Experience",
      subtitle:
        "Direct engaging robotic learning builds skills and ignites creativity.",
    },
  ];

  return (
    <section className="common-section what-we-offer-section">
      <div className="container">
        <div>
          <p className="horizontal-styled-span"></p>
          <p className="common-title mt-lg-3 mb-2 mt-3">
            Our <span className="common-title-span">USP's</span>{" "}
          </p>
        </div>
        <div>
          {" "}
          <ImageTextGrid
            items={items}
            columnsLargeScreen={3}
            columnsMediumScreen={6}
            columnSmallScreen={6}
            columnExtraSmallScreen={6}
            divCss="what-we-offer-imageGrid"
          />
        </div>
      </div>
    </section>
  );
};

export default WhatWeOffer;
