import React from "react";
import { Link, useLocation } from "react-router-dom";
import WhyRoboticsLab from "../HomePages/WhyRoboticsLab";
import NextNews from "./NextNews";

const NewsDetails = () => {
  const location = useLocation();
  const newsData = location.state.newsCardsData;
  return (
    <section className="common-section mt-5">
      <div className="container mt-4">
        <div className="d-flex">
          <Link
            to="/"
            className="prevBreadCrumb"
            style={{ color: "rgba(0, 0, 0, 1)" }}
          >
            Home /
          </Link>
          <Link
            to="/news"
            className="activeBreadCrumb"
            style={{ color: "rgba(0, 0, 0, 1)" }}
          >
            News
          </Link>
        </div>

        <div className="mt-4">
          <div className="d-flex">
            <p className="blogAuthor">{newsData.newsAuthor}</p>{" "}
            <span className="vertical-line"></span>
            <p className="blogDate">{newsData.date}</p>
          </div>

          <img src={newsData.img} alt="blog-img" className="img-fluid" />
          <p className="blogTitle mt-5">{newsData.newsTitle}</p>
          <p className="blogDesc mt-4">{newsData.newsFullDesc}</p>
        </div>

        {/* what to next read news component */}
        <NextNews newsName={newsData.newsTitle} />
      </div>
      
      {/* why robotics lab component */}
      <WhyRoboticsLab />
    </section>
  );
};

export default NewsDetails;
