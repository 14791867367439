import React from "react";
import "../../assets/css/HomeCss/FoundationLab.css";
import ShimmerEffect from "../../components/ShimmerEffect";
import KnowMoreBtn from "../../components/KnowMoreBtn";
const FoundationLab = () => {
  return (
    <section className="foundation-lab-section common-section">
      <div className="container">
        <p className="lab-section-title">Foundation Lab</p>
        <p className="lab-section-subtitle mt-lg-4">
          Our Foundation Lab offers Standard Robotics Technology,
          &nbsp;empowering students to enhance STEM skills and master advanced
          technology, while transforming knowledge into real-world applications,
          setting the stage for Future Innovators.
        </p>
        <div className="foundation-lab-images mt-4">
          <ShimmerEffect
            src={require("../../assets/images/Foundation-Lab/Innovation-Tech-Robotics-Lab.webp")}
            alt="Innovation-Tech-Robotics-Lab"
            shimmerHeight="300"
            shimmerWeight="600"
            imgWidth="100%"
            imgHeight="80%"
          />
          <ShimmerEffect
            src={require("../../assets/images/Foundation-Lab/Future-Tech-Lab.webp")}
            alt="Future-Tech-Lab"
            shimmerHeight="300"
            shimmerWeight="600"
            imgWidth="100%"
            imgHeight="80%"
          />
        </div>

        {/* <KnowMoreBtn sectionName="Foundation Lab" /> */}
      </div>
    </section>
  );
};

export default FoundationLab;
