import React, { useState } from "react";
import ReactPlayer from "react-player";
import "../../assets/css/WhatClientSay.css";

const WhatClientSay = () => {
  const [playingVideo, setPlayingVideo] = useState(null);
  

  const videoData = [
    {
      url: "https://phntechnology-website.s3.ap-south-1.amazonaws.com/testimonial-videos/Valley+View+School_Principal+Testimonial+1.mp4",
      light: require("../../assets/images/What-Our-Client-say/valley-view-high-school.webp"),
      principalName: "Ms. Sangeeta K",
      schoolName: "Principal - Valley View High School, Pune",
    },
    {
      url: "https://phntechnology-website.s3.ap-south-1.amazonaws.com/testimonial-videos/Acharya+Atre+Vikas+Pratishthan%2C+Purandar+testtimonial.mp4",
      light: require("../../assets/images/What-Our-Client-say/acharya-atre-vikas-pratishthan.webp"),
      principalName: "Mr. Vijay Kolthe",
      schoolName: "Group of School - Acharya Atre Vikas Pratishthan, Pune",
    },
  ];

  const videoPlayingStatus = (ind) => {
    setPlayingVideo(ind === playingVideo ? null : ind);
  };

  return (
    <section className="common-section what-client-say-section">
      <div className="container">
        <p className="horizontal-styled-span"></p>
        <p className="common-title mt-lg-3 mt-3">
          What Our <span className="common-title-span"> Client Say?</span>
        </p>

        <div className="row gap-4 gap-lg-0">
          {videoData.map((data, ind) => (
            <div className="col-lg-6 col-12" key={ind}>
              <div className="react-player-wrapper">
                {playingVideo === ind ? (
                  <div className="react-player-container">
                    
                  <ReactPlayer
                    url={data.url}
                    controls
                    playing
                    className="react-media-player"
                    width="100%"
                    height="auto"
                    onEnded={() => setPlayingVideo(null)}
                  />
                  </div>
                ) : (
                  <img
                    src={data.light}
                    alt="thumbnail"
                    className="react-media-player-thumbnail"
                    onClick={() => videoPlayingStatus(ind)}
                    width="98%"
                  />
                )}
              </div>
              <p className="principal-name mb-0 mb-lg-2 mt-3">
                {data.principalName}
              </p>
              <p className="school-name">{data.schoolName}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhatClientSay;
