import React, { useEffect, useState } from "react";
import "../../assets/css/HomeCss/PartnershipWithDelhi.css";
import { useMediaQuery } from "@mui/material";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
const PartnershipAndCollaborations = () => {
  const mobView = useMediaQuery("(max-width:991px)");

  const partnershipData = [
    {
      title:
        "With the Indian Institute of Information Technology, Vadodara (IIIT Vadodara)",
      description: (
        <>
          <p>
            We are proud to announce that we have done MoU with prestigious
            Indian Institute of Information Technology(IIIT), Vadodara.
          </p>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Principal Aspects of the Collaboration:
          </span>{" "}
          <br />
          <ul>
            <li>
              The Mission : With this relationship, we are taking a big step
              toward our goal of revolutionizing education by utilizing
              cutting-edge robotics lab and artificial intelligence technologies
            </li>
            <li>
              Hands-On-Learning: With this partnership, We offers hands-on
              learning in Robotics, AI, and IoT, providing practical experience
              through workshops, real-world projects, and interactive sessions.
            </li>
          </ul>
        </>
      ),
      imageSrc: require("../../assets/images/Partnership-With-Delhi/Mou-Partnership-With-IIT-Vadodara.webp"),
    },
    {
      title:
        "A Significant Milestone in Robotics and AI: The Partnership with IIT Jammu.",
      description: (
        <>
          <p>
            An innovative Center of Excellence (CoE) in robotics, artificial
            intelligence (AI), and the internet of things (IoT) has been
            established at IIT Jammu's Jagti campus thanks to a partnership
            between PHN Technology Pvt. Ltd. and the Institute Incubation and
            Innovation Council (I3C) at IIT Jammu.
          </p>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Principal Aspects of the Collaboration:
          </span>{" "}
          <br />
          <ul>
            <li>
              Practical Training: Provides professionals, educators, and
              students with hands-on experience in robotics, AI, and IoT.
            </li>
            <li>
              Entrepreneurship Promotion: Aims to support technical innovation
              and entrepreneurship in the area.
            </li>
            <li>
              Skilled Workforce Development: Aims to equip Jammu and Kashmir
              with a skilled labor force.
            </li>
          </ul>
        </>
      ),
      imageSrc: require("../../assets/images/Partnership-With-Delhi/Mou-Partnership-With-IIT-Jammu.webp"),
    },
    {
      title: "IIT Patna and PHN Technology Establish Landmark Partnership ",
      description: (
        <>
          <p>
            Announcing of our collaboration with the prestigious Indian
            Institute of Technology, Patna (IIT Patna) is with great pleasure.
          </p>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Principal Aspects of the Collaboration:
          </span>{" "}
          <br />
          <ul>
            <li>
              Advanced AI and Robotics Labs: This partnership demonstrates our
              dedication to promoting education by establishing advanced AI and
              Robotics Labs
            </li>
            <li>
              Innovation and Inspiration: We aspire to encourage innovation and
              motivate the upcoming generation of tech leaders and entrepreneurs
              by collaborating with IIT Patna.
            </li>
          </ul>
        </>
      ),
      imageSrc: require("../../assets/images/Partnership-With-Delhi/Mou-Partnership-With-IIT-patna.webp"),
    },
    {
      title:
        "Robotics and AI: PHN Technology and IIT Guwahati Open Up New Boundaries ",
      description: (
        <>
          <p>
            We are happy to declare that we have signed a new partnership with
            the prestigious Indian Institute of Technology, Guwahati (IIT
            Guwahati).
          </p>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Principal Aspects of the Collaboration:
          </span>{" "}
          <br />
          <ul>
            <li>
              Strategic collaboration: PHN Technology and IIT Guwahati have
              entered into a strategic partnership as indicated by this
              Memorandum of Understanding (MoU).
            </li>
            <li>
              Attention to detail: The collaboration seeks to advance robotics
              and artificial intelligence research and development.
            </li>
            <li>
              Dedication to Excellence: We are all dedicated to promoting
              education and technology to provide practical and affordable
              solutions in the future.
            </li>
          </ul>
        </>
      ),

      imageSrc: require("../../assets/images/Partnership-With-Delhi/Mou-Partnership-With-IIT-guwahati.webp"),
    },
    {
      title: "Prominent Partnership with NIT Delhi",
      description: (
        <>
          <p>
            With great pleasure, we announce that PHN Technology has signed a
            Memorandum of Understanding (MoU) with the esteemed National
            Institute of Technology, Delhi (NIT Delhi).
          </p>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Principal Aspects of the Collaboration:
          </span>{" "}
          <br />
          <ul>
            <li>
              The Mission : With this relationship, we are taking a big step
              toward our goal of revolutionizing education by utilizing
              cutting-edge robotics lab and artificial intelligence technologies
            </li>
            <li>
              Targets: In collaboration with NIT Delhi, we hope to develop
              research capacities, support creativity, and give students access
              to innovative technological experiences that will influence
              education in the future.
            </li>
          </ul>
        </>
      ),
      // imageSrc: require("../../assets/images/Partnership-With-Delhi/Mou-Partnership-With-ITI.webp"),
      imageSrc: require("../../assets/images/Partnership-With-Delhi/Mou-Partnership-With-NIT.webp"),
    },
    {
      title: "Driven by Innovation: A Collaboration with ITI Ltd.",
      description: (
        <>
          <p>
            PHN Technology is proud to announce a new strategic partnership with
            ITI Limited, a Central PSU under the Department of
            Telecommunications, Ministry of Communications, Government of India.
          </p>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Principal Aspects of the Collaboration:
          </span>{" "}
          <br />
          <ul>
            <li>ICT-based e-Governance</li>
            <li>Software and Cloud-based Solutions</li>
            <li>Drone Technology</li>
            <li>Distance Learning and Meeting Solutions</li>
          </ul>
        </>
      ),
      imageSrc: require("../../assets/images/Partnership-With-Delhi/Mou-Partnership-With-ITI.webp"),
    },
    {
      title:
        "PHN Technology Pvt Ltd and BELOP Establish a Strategic Collaboration",
      description: (
        <>
          <p>
            We are happy to announce that BEL Optronic Devices Ltd. (BELOP) and
            PHN Technology Pvt. Ltd. have signed a Memorandum of Understanding
            (MoU). BELOP is a Defense Public Sector Undertaking (PSU) and a
            subsidiary of BEL.
          </p>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Principal Aspects of the Collaboration:
          </span>{" "}
          <br />
          <ul>
            <li>
              Enhanced Educational Solutions: The goal of this partnership is to
              improve PHN's technology and advanced education offerings.
            </li>
            <li>
              Innovation Commitment: Reiterating our goal of providing students
              with the knowledge and abilities need to enter the workforce
              prepared for the future.
            </li>
          </ul>
        </>
      ),
      imageSrc: require("../../assets/images/Partnership-With-Delhi/Mou-Partnership-With-BELOP.webp"),
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  useEffect(() => {
    gsap.utils.toArray(".section").forEach((section) => {
      if (section.classList.contains("horizontal")) {
        const cardsWrap = section.querySelector(".section__cards");
        const oneCard = section.querySelector(".section__card");

        gsap.to(cardsWrap, {
          x: () => -(cardsWrap.scrollWidth - oneCard.offsetWidth),
          ease: "none",
          scrollTrigger: {
            trigger: section,
            start: "center 57%",
            end: () => "+=" + (cardsWrap.scrollWidth - oneCard.offsetWidth),
            scrub: true,
            pin: true,
            invalidateOnRefresh: true,
            anticipatePin: 1,
          },
        });
      } else {
        ScrollTrigger.create({
          markers: true,
          trigger: section,
          start: "top top",
          pin: true,
          anticipatePin: 1,
        });
      }
    });
  }, []);

  const truncateText = (jsx, limit) => {
    const div = document.createElement("div");
    div.innerHTML = jsx.props.children
      .map((child) =>
        typeof child === "string" ? child : child.props.children
      )
      .flat()
      .filter(Boolean)
      .join(" ");
    const text = div.innerText || div.textContent || "";
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  };

  const descriptionLimit = 160;

  return (
    <>
      {/* code for mobile view */}

      {mobView ? (
        <section className="common-section partnership-section">
          <div className="container">
            <p className="horizontal-styled-span"></p>
            <p className="common-title">
              <span className="common-title-span">Partnership </span>
              and Collaboration
            </p>
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
              data-pause="true"
            >
              <div className="carousel-indicators">
                {partnershipData.map((_, index) => (
                  <button
                    key={index}
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    aria-current={index === 0 ? "true" : undefined}
                    aria-label={`Slide ${index + 1}`}
                  ></button>
                ))}
              </div>
              <div className="carousel-inner mt-4">
                {partnershipData.map((item, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <div className="row">
                      <div className="col-lg-7 col-12 order-lg-1 order-2">
                        <p className="partnership-title">{item.title}</p>
                        <div className="partnership-details">
                          {expandedIndex === index ? (
                            item.description
                          ) : (
                            <>
                              {truncateText(item.description, descriptionLimit)}
                              <button
                                className="read-more-btn btn"
                                onClick={() => handleToggle(index)}
                              >
                                Read More
                              </button>
                            </>
                          )}
                          {expandedIndex === index && (
                            <button
                              className="read-more-btn btn"
                              onClick={() => handleToggle(index)}
                            >
                              Read Less
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-5 col-12 order-lg-2 order-1">
                        <img
                          src={item.imageSrc}
                          className="d-block w-100"
                          alt={item.title}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      ) : (
        // {/* code for desktop view */}

        <div className="common-section partnership-with-delhi-section ">
          <div className="container">
            <p className="horizontal-styled-span"></p>
            <p className="common-title partnership-main-title">
              <span className="common-title-span">Partnership </span>
              and Collaboration
            </p>{" "}
          </div>
          <section className="section horizontal">
            <div className="section__cards">
              {partnershipData.map((data, ind) => {
                return (
                  <div className="section__card">
                    <div>
                      <div
                        className="ps-4 my-auto"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <p className="cards-title  my-auto">{data.title}</p>
                        <p className="card-desc mt-3  my-auto" key={ind}>
                          {data.description}
                        </p>{" "}
                      </div>
                      <div className="m-auto text-center">
                        {" "}
                        <img
                          src={data.imageSrc}
                          alt={`Image for ${data.title}`}
                          className="partnership-img m-auto"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default PartnershipAndCollaborations;
