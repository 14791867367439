import React, { useEffect, useState } from "react";
import ShimmerEffect from "../ShimmerEffect";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Helmet } from "react-helmet-async";
import CommonHeader from "../CommonHeader";
import "../../assets/css/OurProducts/OurProducts.css";
import WhyRoboticsLab from "../../pages/HomePages/WhyRoboticsLab";
import { Link } from "react-router-dom";
const OurProducts = ({ data, breadcrumbData }) => {
  const [changeImg, setChangeImg] = useState();
  useEffect(() => {
    setChangeImg(0);
  }, [data?.images]);
  const allLabsData = [
    {
      img: require("../../assets/images/AboutUs/Advisor/check-out-more-labs/coe-lab.webp"),
      title: "Center of Excellence Lab",
      data: "An ultra-modern facility for advanced AI and Robotics Research.",
      viewDetailsLink: "/center-of-excellence-lab",
    },
    {
      img: require("../../assets/images/AboutUs/Advisor/check-out-more-labs/enterpreneurship-lab.webp"),
      title: "Entrepreneurship Lab",
      data: "Lab promotes student Entrepreneurship and leadership.",
      viewDetailsLink: "/entrepreneurship-lab",
    },
    {
      img: require("../../assets/images/AboutUs/Advisor/check-out-more-labs/prototype-lab.webp"),
      title: "Prototype & Innovation Lab",
      data: "Where students build and test Prototype Robots, bringing ideas to life.",
      viewDetailsLink: "/prototype-and-innovation-lab",
    },

    {
      img: require("../../assets/images/AboutUs/Advisor/check-out-more-labs/foundation-lab.webp"),
      title: "Foundation Lab",
      data: "Lab offers Standard Robotics Technology, enhancing STEM skills.",
      viewDetailsLink: "/foundation-lab",
    },
  ];

  const filteredLabs = allLabsData.filter((lab) => lab.title !== data.labName);
  return (
    <>
      {/* hero section and seo data */}
      <section className="our-products-section">
        <Helmet>
          <title>{data?.seo.title}</title>
          <meta name="description" content={data?.seo.description} />
        </Helmet>
        <h1 hidden>{data?.seo.hiddenTitle}</h1>
      </section>
      <section className="common-section our-products-section pb-0">
        <div className="container">
          <p className="horizontal-styled-span"></p>
          <p className="common-title-span"> {data?.mainTitle}</p>
          <div className="lab-desk-view mt-5">
            <ShimmerEffect
              src={data?.images[changeImg]}
              alt="lab-image"
              shimmerHeight="500"
              shimmerWeight="1200"
              imgWidth="200"
              nameOfImgClass="img-fluid m-auto"
              style={{ borderRadius: "24px" }}
            />
            <div className="d-flex justify-content-center gap-5 mt-5">
              {data?.images?.map((data, ind) => {
                return (
                  <img
                    src={data}
                    alt="lab-images"
                    width={150}
                    height={110}
                    key={ind}
                    onClick={() => setChangeImg(ind)}
                    style={{
                      border: changeImg === ind ? "2px solid blue" : "",
                      cursor: "pointer",
                      borderRadius: "24px",
                    }}
                  />
                );
              })}
            </div>
          </div>
          {/* on click view full img */}
          <PhotoProvider>
            <div className="lab-mob-view">
              {data?.images?.map((image, ind) => (
                <PhotoView key={`${data.mainTitle}-${ind}`} src={image}>
                  <img
                    src={image}
                    alt="lab-images"
                    width={300}
                    height={200}
                    style={{ borderRadius: "12px" }}
                  />
                </PhotoView>
              ))}
            </div>
          </PhotoProvider>

          {/* about lab */}
          <div style={{ paddingTop: "64px" }}>
            <div className="row">
              <p className="horizontal-styled-span ms-3"></p>
              <p className="common-title mb-0 mb-lg-4">
                About
                <span className="common-title-span"> {data?.mainTitle}</span>
              </p>
              <div className="col-12 order-lg-1 order-2">
                <p className="about-data">{data?.aboutData[0]}</p>
              </div>
            </div>
          </div>

          {/* benefits */}
          <div className="common-section benefits-section">
            <p className="horizontal-styled-span"></p>
            <p className="common-title-span">Benefits</p>
            <div className="row">
              {data?.benefits?.map((data, ind) => {
                return (
                  <div className="col-lg-4 col-12 benefits-column" key={ind}>
                    <img
                      src={data?.img}
                      alt="benefits-icon"
                      className="img-fluid benefits-img"
                    />
                    <p className="benefits-data">{data?.text}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* check out more labs */}

      <section className="check-out-more-lab-section common-section">
        <div className="container">
          <p className="horizontal-styled-span mb-3"></p>
          <p className="common-title">
            Checkout Our <span className="common-title-span">Other Labs</span>{" "}
          </p>

          <div className="checkout-wrapper">
            {filteredLabs.map((lab, index) => (
              <div className="checkout-cards" key={index}>
                <img src={lab.img} alt={lab.title} />
                <p className="check-out-lab-title">{lab.title}</p>
                <p className="check-out-lab-data">{lab.data}</p>
                <Link
                  to={lab.viewDetailsLink}
                  className="btn checkout-view-btn"
                >
                  View Details
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* why robotics lab component */}
      <WhyRoboticsLab />
    </>
  );
};

export default OurProducts;
