import React, { useState } from "react";
import "../../assets/css/Blogs/BlogsMainPage.css";
import BlogCards from "./BlogCards";
import WhyRoboticsLab from "../HomePages/WhyRoboticsLab";
const BlogsMainPage = () => {
  const blogChips = ["Business", "Startup", "Economy", "Technology"];
  const [activeChip, setActiveChip] = useState("Business");
  return (
    <>
      <section className="common-section mt-5 blogs-main-section">
        <div className="container mt-4 mb-5">
          <p className="horizontal-styled-span mb-0"></p>
          <p className="common-title-span">Blogs</p>

          {/* chips */}
          <div className="blogChipsWrapper">
            {blogChips.map((chipsName, ind) => {
              return (
                <p
                  className={`blog-chips ${
                    activeChip === chipsName ? "chipActive" : ""
                  }`}
                  key={ind}
                  onClick={() => setActiveChip(chipsName)}
                >
                  {chipsName}
                </p>
              );
            })}
          </div>

          <>
            {/*cards of blogs component */}

            <BlogCards activeChip={activeChip} />
          </>
        </div>
        <WhyRoboticsLab />
      </section>
    </>
  );
};

export default BlogsMainPage;
