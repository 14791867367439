import React, { useEffect, useState, useRef } from "react";
import ButtonStructure from "./ButtonStructure";
import PhnWhiteLogo from "../assets/images/Hero-Section/Phn-White-Logo.svg";
import PhnBlueLogo from "../assets/images/Hero-Section/Phn-Blue-Logo.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../assets/css/Navbar.css";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const location = useLocation();
  const navbarRef = useRef(null);

  const handleScroll = () => {
    if (location.pathname === "/") {
      setScrolled(window.scrollY > 0);
    } else {
      setScrolled(true);
    }
  };

  const handleOutsideClick = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setNavbarOpen(false);
      setDropdownOpen(null);
    }
  };

  const handleNavLinkClick = () => {
    if (document.querySelector(".navbar-collapse.show")) {
      document.querySelector(".navbar-toggler").click();
    }
    setNavbarOpen(false);
    setDropdownOpen(null);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (navbarOpen || dropdownOpen !== null) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [navbarOpen, dropdownOpen]);

  let navbarClasses = [
    "navbar",
    "navbar-expand-lg",
    "fixed-top",
    "navbar-light",
  ];
  if (scrolled) {
    navbarClasses.push("navbar-scrolled");
  }

  return (
    <section className="navbar-section">
      <div className="container" style={{ position: "relative" }}>
        <nav className={`p-0 ${navbarClasses.join(" ")}`} ref={navbarRef}>
          <div className={`container`}>
            <p className="navbar-brand pb-0" to="">
              {scrolled ? (
                <Link to="/" onClick={handleNavLinkClick}>
                  <img
                    src={PhnBlueLogo}
                    alt="PhnBlueLogo"
                    width={100}
                    loading="lazy"
                  />
                </Link>
              ) : (
                <Link to="/" onClick={handleNavLinkClick}>
                  <img
                    src={PhnWhiteLogo}
                    alt="PhnWhiteLogo"
                    width={100}
                    loading="lazy"
                  />
                </Link>
              )}
            </p>
            <div className="ms-4">
              <ButtonStructure
                btnName="Get Free Demo"
                NameOfClass="navbarContactBtnMob"
                to="/contact-us"
              />
            </div>
            <button
              className="navbar-toggler mt-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-label="Toggle navigation"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {scrolled ? (
                <img
                  src={require("../assets/images/Hero-Section/Black-Menu.png")}
                  alt="hamburger"
                  width={30}
                  loading="lazy"
                />
              ) : (
                <img
                  src={require("../assets/images/Hero-Section/White-Menu.png")}
                  alt="hamburger"
                  width={30}
                  loading="lazy"
                />
              )}
            </button>
            <div
              className={`collapse navbar-collapse ${navbarOpen ? "show" : ""}`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item mx-lg-3 my-lg-1 my-2">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    aria-current="page"
                    to="/"
                    onClick={handleNavLinkClick}
                  >
                    Home
                  </NavLink>
                </li>
                <li
                  className="nav-item mx-lg-3 my-lg-1 my-2 dropdown"
                  onMouseEnter={() => setDropdownOpen("products")}
                  onMouseLeave={() => setDropdownOpen(null)}
                >
                  <Link
                    className={`nav-link dropdown-toggle ${
                      location.pathname === "/center-of-excellence-lab" ||
                      location.pathname === "/entrepreneurship-lab" ||
                      location.pathname === "/prototype-and-innovation-lab" ||
                      location.pathname === "/foundation-lab"
                        ? " active"
                        : ""
                    }`}
                    id="productDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded={dropdownOpen === "products"}
                  >
                    PHN Labs
                  </Link>
                  <ul
                    className={`dropdown-menu ${
                      dropdownOpen === "products" ? "show" : ""
                    }`}
                    aria-labelledby="productDropdown"
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/center-of-excellence-lab"
                        state="coeData"
                        onClick={handleNavLinkClick}
                      >
                        Center of Excellence Lab
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/entrepreneurship-lab"
                        state="entrepreneurshipData"
                        onClick={handleNavLinkClick}
                      >
                        Entrepreneurship Lab
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/prototype-and-innovation-lab"
                        state="prototypeData"
                        onClick={handleNavLinkClick}
                      >
                        Prototype & Innovation Lab
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="dropdown-item"
                        to="/foundation-lab"
                        state="foundationData"
                        onClick={handleNavLinkClick}
                      >
                        Foundation Lab
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className="nav-item mx-lg-3 my-lg-1 my-2 dropdown"
                  onMouseEnter={() => setDropdownOpen("media")}
                  onMouseLeave={() => setDropdownOpen(null)}
                >
                  <Link
                    className={`nav-link dropdown-toggle ${
                      location.pathname === "/gallery" ? " active" : ""
                    }`}
                    id="mediaDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded={dropdownOpen === "media"}
                  >
                    Media
                  </Link>
                  <ul
                    className={`dropdown-menu ${
                      dropdownOpen === "media" ? "show" : ""
                    }`}
                    aria-labelledby="mediaDropdown"
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/gallery"
                        onClick={handleNavLinkClick}
                      >
                        Gallery
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* When new tab added add location also in classname for active the tab */}
                {/* <li
                  className="nav-item mx-lg-3 my-lg-1 my-2 dropdown"
                  onMouseEnter={() => setDropdownOpen("media")}
                  onMouseLeave={() => setDropdownOpen(null)}
                >
                  <Link
                    className={`nav-link dropdown-toggle ${
                      location.pathname === "/gallery" ||
                      location.pathname === "/blogs" ||
                      location.pathname === "/news"
                        ? " active"
                        : ""
                    }`}
                    id="mediaDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded={dropdownOpen === "media"}
                  >
                    Resources
                  </Link>
                  <ul
                    className={`dropdown-menu ${
                      dropdownOpen === "media" ? "show" : ""
                    }`}
                    aria-labelledby="mediaDropdown"
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/gallery"
                        onClick={handleNavLinkClick}
                      >
                        Gallery
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/blogs"
                        onClick={handleNavLinkClick}
                      >
                        Blogs
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/news"
                        onClick={handleNavLinkClick}
                      >
                        News
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li
                  className="nav-item mx-lg-3 my-lg-1 my-2 dropdown"
                  onMouseEnter={() => setDropdownOpen("about")}
                  onMouseLeave={() => setDropdownOpen(null)}
                >
                  <Link
                    className={`nav-link dropdown-toggle ${
                      location.pathname === "/about-us" ||
                      location.pathname === "/advisory-board"
                        ? " active"
                        : ""
                    }`}
                    id="aboutDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded={dropdownOpen === "about"}
                  >
                    Our Company
                  </Link>
                  <ul
                    className={`dropdown-menu ${
                      dropdownOpen === "about" ? "show" : ""
                    }`}
                    aria-labelledby="aboutDropdown"
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/about-us"
                        onClick={handleNavLinkClick}
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/advisory-board"
                        onClick={handleNavLinkClick}
                      >
                        Advisory Board
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item mx-lg-3 my-lg-1 my-2">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/careers"
                    onClick={handleNavLinkClick}
                  >
                    Careers
                  </NavLink>
                </li>

                <li
                  className="nav-item mx-lg-3 my-lg-0 my-2 "
                  onClick={handleNavLinkClick}
                >
                  <ButtonStructure
                    btnName="Get Free Demo"
                    NameOfClass="navbarContactBtn"
                    to="/contact-us"
                  />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default Navbar;
