import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import cardData from "../../assets/json/BlogJson.json";

const NextBlog = ({ blogName, activeChip }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:991px)");

  const filteredData = Array.isArray(cardData[activeChip])
    ? cardData[activeChip].filter((data) => blogName !== data.blogTitle)
    : [];

  const navigateToFullBlog = (blogName) => {
    const formattedBlogName = blogName.replace(/ /g, "_");

    const blogData = cardData[activeChip].find(
      (data) => data.blogTitle === blogName
    );

    navigate(`/blogs/${formattedBlogName}`, {
      state: { blogData, activeChip },
    });
  };

  return (
    <section className="common-section">
      {/* code for mobile view */}
      {isMobile ? (
        <>
          <p className="horizontal-styled-span mb-0"></p>
          <p className="common-title">
            What to Read <span className="common-title-span">Next</span>{" "}
          </p>
          <div className="scrollCardsWrapper">
            {filteredData.map((data, ind) => (
              <div
                className="blogCard"
                key={ind}
                onClick={() => navigateToFullBlog(data.blogTitle)}
                style={{ cursor: "pointer" }}
              >
                <img src={data.img} alt="blog-img" className="img-fluid" />
                <div className="blogCardData">
                  <span className="activeChip">{activeChip}</span>
                  <p className="blogTitle">{data.blogTitle}</p>
                  <p className="blogDesc">{data.blogDesc}</p>
                  <p className="blogAuthor mb-0">{data.blogAuthor}</p>
                  <p className="blogDate">{data.date}</p>
                  <p className="blogReadingTime">{data.readingTime}</p>
                </div>
              </div>
            ))}
          </div>{" "}
        </>
      ) : (
        <>
          {/* code for desktop view */}

          <div>
            <p className="horizontal-styled-span mb-0"></p>
            <p className="common-title">
              What to Read <span className="common-title-span">Next</span>{" "}
            </p>

            <div className="row">
              {filteredData.map((data, ind) => (
                <div
                  className="col-lg-4 col-12 col-md-6"
                  key={ind}
                  onClick={() => navigateToFullBlog(data.blogTitle)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="card blogCard">
                    <img src={data.img} alt="blog-img" className="blog-Img" />
                    <div className="blogCardData">
                      <span className="activeChip">{activeChip}</span>
                      <p className="blogTitle">{data.blogTitle}</p>
                      <p className="blogDesc">{data.blogDesc}</p>
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <p className="blogAuthor mb-0">{data.blogAuthor}</p>
                          <p className="blogDate">{data.date}</p>
                        </div>
                        <div className="col-lg-6 col-12 my-auto">
                          <p className="blogReadingTime">{data.readingTime}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default NextBlog;
