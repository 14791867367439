import React, { useEffect } from "react";
import ContactHeroSection from "./ContactHeroSection";
import "../../assets/css/ContactCss/ContactUs.css";
import ContactForm from "./ContactForm";
import FAQ from "./FAQ";
import ContactUsCards from "./ContactUsCards";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
  const location = useLocation();
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#Faq") {
      const element = document.getElementById("Faq");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <section className="common-section">
      {/* contact Us SEO */}
      <Helmet>
        <title>PHN Technology | Advanced AI & Robotics Lab Enquiry Form</title>
        <meta
          name="description"
          content="Are you looking to set up advanced Ai & Robotics Lab at your school? We can help! We are a leading provider of robotics lab setup services for schools. Interested in setting up a robotics lab at your school or college? Inquire with us."
        />
      </Helmet>
      {/* contact us hero section component*/}
      <ContactHeroSection />

      {/* contact us form component*/}
      <ContactForm />

      {/* contact us cards component*/}
      <ContactUsCards />

      {/* FAQ component*/}
      <div id="Faq">
        <FAQ />
      </div>
    </section>
  );
};

export default ContactUs;
