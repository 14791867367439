import React, { useState } from "react";
import timeIcon from "../../assets/images/ContactUs/time-icon.svg";
import locationIcon from "../../assets/images/ContactUs/location-icon.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const [inputData, setInputData] = useState({
    full_name: "",
    mobile_no: "",
    subject: "",
    email: "",
    enquiry_details: "",
  });
  const [errors, setErrors] = useState({});
  const subRegex = /^[a-zA-Z0-9 ]*$/;
  const nameRegex = /^[A-Za-z' ]*$/;
  const mobileRegx = /^[6789][0123456789]{9}$/;
  const emailRegx = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9]+[.-][a-zA-Z][a-z.A-Z]+$/;

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "full_name":
        if (!value) {
          error = "Please enter full name.";
        } else if (!nameRegex.test(value)) {
          error = "Please enter valid full name.";
        }
        break;
      case "mobile_no":
        if (!value) {
          error = "Please enter mobile no.";
        } else if (!mobileRegx.test(value)) {
          error = "Please enter valid mobile no.";
        }
        break;
      case "subject":
        if (!value) {
          error = "Please enter subject.";
        } else if (!subRegex.test(value)) {
          error =
            "Please enter valid subject. Special characters are not allowed";
        }
        break;
      case "email":
        if (!value) {
          error = "Please enter email address.";
        } else if (!emailRegx.test(value)) {
          error = "Please enter valid email address.";
        }
        break;
      case "enquiry_details":
        if (!value) {
          error = "Please enter description.";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let filteredValue = value;

    if (name === "full_name") {
      const re = /^[A-Za-z' ]*$/;

      filteredValue = re.test(value) ? value : inputData[name];
    }
    if (name === "mobile_no") {
      const re = /^[0-9]*$/;
      filteredValue = re.test(value) ? value : inputData[name];
    }

    setInputData({
      ...inputData,
      [name]: filteredValue,
    });

    validateField(name, filteredValue);
  };

  // Validate form
  const validateForm = () => {
    let formErrors = {};
    for (const field in inputData) {
      const error = validateField(field, inputData[field]);
      if (error) formErrors[field] = error;
    }
    return formErrors;
  };

  // Handle form submission
  var token = localStorage.getItem("authtoken");
  const COE_FORM_URL = "https://phntechnology.com/backend/api/contactSubmit";
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      // console.log("Form is valid:", inputData);
      const data = {
        contact_name: inputData.full_name,
        contact_email: inputData.email,
        contact_mobile: inputData.mobile_no,
        contact_subject: inputData.subject,
        contact_message: inputData.enquiry_details,
      };
      try {
        const response = await fetch(COE_FORM_URL, {
          method: "POST",
          headers: {
            authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        let resJson = await response.json();
        const msg = resJson.message;
        if (msg === "Contact info submitted successfully") {
          toast.success(
            "Data submitted successfully. Please check your email for more details.",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setInputData({
            full_name: "",
            mobile_no: "",
            email: "",
            subject: "",
            enquiry_details: "",
          });
        }
        // console.log("Form submitted successfully");
      } catch (err) {
        console.log("Error Ocurred", err);
        toast.error("Something went wrong, Please try again later!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <section className="common-section contact-us-form-section">
      <div className="container">
        <ToastContainer />
        <p className="horizontal-styled-span mt-lg-4 mb-1"></p>
        <p className="common-title">
          Book Your <span style={{ fontWeight: "700" }}>Free Demo</span>
        </p>
        <div className="row">
          <div className="col-lg-6 col-12 order-lg-1 order-2 my-lg-0 my-5">
            {/* <p className="contact-details">
              <img src={phoneIcon} alt="phoneIcon" className="me-2" />
              +91 924563974{" "}
            </p> */}

            {/* <p className="contact-details">
              <img src={mailIcon} alt="mailIcon" className="me-2" />
              info@phntechnology.com
            </p> */}

            <p className="contact-details">
              <img src={timeIcon} alt="timeIcon" className="me-2" />
              Hours, Mon-Fri 9 am - 8 pm
            </p>

            <p className="contact-details d-flex">
              <div>
                <img src={locationIcon} alt="locationIcon" className="me-2" />
              </div>
              Solitaire Business Hub E Wing 5010, F wing 5010 & 5020, 5th Floor
              Viman Nagar, Pune, Maharashtra 411014.
            </p>

            <div className="text-center">
              <iframe
                src="
https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6360.82232627717!2d73.91689494430219!3d18.56648195729964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc29549e9ddd8e1%3A0xf57b5e702d9a0c8b!2sPHN%20Technology%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1718978839136!5m2!1sen!2sin"
                className="google-map"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6 col-12 order-lg-2 order-1">
            <div className="contact-form m-auto">
              <div className="container">
                <form>
                  <div className="fullName">
                    <label>Full Name</label>
                    <input
                      type="text"
                      name="full_name"
                      className="form-control contact-form-input"
                      placeholder="Full Name"
                      onChange={handleChange}
                      value={inputData.full_name}
                    />
                    {errors.full_name && (
                      <p className="error-msg">{errors.full_name}</p>
                    )}
                  </div>

                  <div className="email">
                    <label>Email</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control contact-form-input"
                      onChange={handleChange}
                      value={inputData.email}
                      placeholder="Email Address"
                    />
                    {errors.email && (
                      <p className="error-msg">{errors.email}</p>
                    )}
                  </div>
                  <div className="mobNo">
                    <label>Mobile No</label>

                    <input
                      type="tel"
                      name="mobile_no"
                      minLength={10}
                      maxLength={10}
                      className="form-control contact-form-input"
                      onChange={handleChange}
                      value={inputData.mobile_no}
                      placeholder="Mobile Number"
                    />
                    {errors.mobile_no && (
                      <p className="error-msg">{errors.mobile_no}</p>
                    )}
                  </div>
                  <div className="subject">
                    <label>Subject</label>
                    <input
                      type="text"
                      name="subject"
                      className="form-control contact-form-input"
                      onChange={handleChange}
                      value={inputData.subject}
                      placeholder="Subject"
                    />
                    {errors.subject && (
                      <p className="error-msg">{errors.subject}</p>
                    )}
                  </div>
                  <div className="enquiryDetails">
                    <label>Description</label>

                    <textarea
                      name="enquiry_details"
                      rows="3"
                      onChange={handleChange}
                      className="form-control contact-form-input"
                      placeholder="Describe your Enquiry"
                      value={inputData.enquiry_details}
                    ></textarea>
                  </div>
                  {errors.enquiry_details && (
                    <p className="error-msg">{errors.enquiry_details}</p>
                  )}

                  <input
                    type="submit"
                    value="Submit"
                    className="btn form-submit-btn enquiry-form-submit-btn text-light"
                    onClick={handleSubmit}
                  />
                  {/* <img src={rightArrow} alt="rightArrow" className="submit_btn_arrow" /> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
