import React from "react";
import { Link, useLocation } from "react-router-dom";
import WhyRoboticsLab from "../HomePages/WhyRoboticsLab";
import NextInnovation from "./NextInnovation";

const InnovationDetails = () => {
  const location = useLocation();
  const InnovativeData = location.state.InnovativeData;

  return (
    <section className="common-section mt-5">
      <div className="container mt-4">
        <div className="d-flex">
          <Link
            to="/"
            className="prevBreadCrumb"
            style={{ color: "rgba(0, 0, 0, 1)" }}
          >
            Home /
          </Link>
          <Link
            to="/innovations"
            className="activeBreadCrumb"
            style={{ color: "rgba(0, 0, 0, 1)" }}
          >
            Innovation
          </Link>
        </div>

        <div className="mt-4">
          <img src={InnovativeData.img} alt="blog-img" className="img-fluid" />
          <p className="blogTitle mt-4">{InnovativeData.title}</p>
          <p className="blogDesc mt-4">{InnovativeData.desc}</p>
        </div>
        {/* what to next read innovation component */}
        <NextInnovation innovationName={InnovativeData.title} />
      </div>
      
      {/* why robotics lab component */}
      <WhyRoboticsLab />
    </section>
  );
};

export default InnovationDetails;
