import React, { useState, useEffect } from "react";
import "../../assets/css/Labs.css";
import CoElab from "../../assets/images/Labs/CoELab.webp";
import CoEMobile from "../../assets/images/Labs/CoELab-MobileView.webp";
import EntrepreneurshipLabMobile from "../../assets/images/Labs/EntreprenureLab-MobileView.webp";
import PrototypeLabMobile from "../../assets/images/Labs/PrototypeLab-MobileView.webp";
import EntrepreneurshipLab from "../../assets/images/Labs/Entrepreneurship-Lab.webp";
import PrototypeLab from "../../assets/images/Labs/Prototype-Lab.webp";
import InactiveIndicator from "../../assets/images/Labs/Inactive-indication.svg";
import activeIndicator from "../../assets/images/Labs/Active Indication.svg";
import RedirectArrow from "../../assets/images/Labs/RedirectArrow.svg";
import { useNavigate } from "react-router-dom";
import ShimmerEffect from "../../components/ShimmerEffect";
const Labs = () => {
  const navigate = useNavigate();
  const labs = [
    {
      id: 1,
      title: "Center of Excellence Lab",
      subtitle:
        "An ultra-modern facility for advanced AI and Robotics Research.",
      image: CoElab,
      mobileImage: CoEMobile,
      to: "/center-of-excellence-lab",
      state: "coeData",
    },
    {
      id: 2,
      title: "Entrepreneurship Lab",
      subtitle:
        "Lab Empowers Students with Entrepreneurial Skills, Business Acumen, and Leadership.",
      image: EntrepreneurshipLab,
      mobileImage: EntrepreneurshipLabMobile,
      to: "/entrepreneurship-lab",
      state: "entrepreneurshipData",
    },
    {
      id: 3,
      title: "Prototype & Innovation Lab",
      subtitle:
        "Where students build and test Prototype Robots, bringing ideas to life.",
      image: PrototypeLab,
      mobileImage: PrototypeLabMobile,
      to: "/prototype-and-innovation-lab",
      state: "prototypeData",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

  const handleCardClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="common-section labs-section">
      <div className="container lab-section-container">
        <p className="horizontal-styled-span mb-0"></p>
        <p className="common-title mt-lg-3 mb-3">
          PHN <span className="common-title-span"> Labs</span>
        </p>
        <div>
          <p className="lab-section-title">Future Experience Center</p>
          <p className="common-description mb-5 lab-section-subtitle">
            Our Future Experience Center, where kids explore, innovate, and
            shape tomorrow's world. Our center houses three labs: The Center of
            Excellence Lab, The Entrepreneurship Lab and The Prototype &
            Innovation Lab. Here, children not only learn but also create,
            fostering the skills they need to become future innovators and excel
            in their careers.
          </p>
        </div>
        {/*code For mobile */}
        {isMobile ? (
          <div className="lab-mobile-card-wrapper">
            {labs?.map((data) => (
              <div
                className="card lab-mobile-card"
                onClick={() => navigate(data.to, { state: data.state })}
              >
                <div className="card-body lab-mobile-card-body" key={data.id}>
                  <div>
                    <img
                      className="labs-mobile-view-image"
                      src={data.mobileImage}
                      alt="CoE-lab"
                      loading="lazy"
                    />
                  </div>
                  <div className="mt-3 lab-mobile-text-wrapper">
                    <div id="lab-mobile-title-textWrapper">
                      <p className="lab-mobile-title mb-1">{data.title}</p>
                      <span>
                        <img
                          src={RedirectArrow}
                          alt="redirect"
                          width="100%"
                          loading="lazy"
                          className="redirect-button-img-mob"
                        />
                      </span>
                    </div>

                    <p className="lab-mobile-subtitle">{data.subtitle}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          //  code for desktop

          <div className="labs-section-scroll-wrapper row mt-5">
            <div className="labs-section-scroll-left col-6 col-md-12 col-lg-5 col-xl-6">
              <div className="labs-section-left-wrapper">
                {labs.map((data, index) => (
                  <div
                    className={`labs-left-block ${
                      index === activeIndex ? "active" : ""
                    }`}
                    key={data.id}
                    onMouseEnter={() => handleCardClick(index)}
                    onClick={() => navigate(data.to, { state: data.state })}
                  >
                    <div className="lab-card card">
                      <div className="card-body lab-card-body row">
                        <div className="col-1">
                          <img
                            src={
                              index === activeIndex
                                ? activeIndicator
                                : InactiveIndicator
                            }
                            alt="indicator"
                            loading="lazy"
                            className="lab-indicator"
                          />
                        </div>
                        <div className="col-11">
                          <div className="d-flex justify-content-between">
                            <p className="labs-text-title">{data.title}</p>
                            <div className="redirect-button-wrapper">
                              <img
                                src={RedirectArrow}
                                alt="redirect"
                                loading="lazy"
                              />
                            </div>
                          </div>
                          <p className="lab-section-subtitle">
                            {data.subtitle}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="labs-section-scroll-right col-6 col-md-12 col-lg-7 col-xl-6 my-auto">
              <div className="labs-right-block">
                <div className="labs-right-image-wrapper">
                  {labs[activeIndex] && (
                    <ShimmerEffect
                      src={labs[activeIndex].image}
                      alt={labs[activeIndex].title}
                      shimmerHeight="400"
                      shimmerWeight="500"
                      nameOfImgClass="labs-right-image img-fluid"
                    />

                    // <img  src={labs[activeIndex].image}
                    // alt={labs[activeIndex].title} className="labs-right-image img-fluid"/>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Labs;
