import React, { useRef, useState } from "react";
import "../../assets/css/ExperienceCenter.css";

const ExperienceCenter = () => {
  const experienceCenterData = [
    {
      img: require("../../assets/images/Experience-Center/Robotics-IOT.webp"),
      title: "Robotics & IoT",
      subtitle: "Machines interacting with the physical world.",
    },

    {
      img: require("../../assets/images/Experience-Center/AR-VR.webp"),
      title: "AR/VR",
      subtitle: "Augmented and Virtual Reality altering perceived actuality.",
    },
    {
      img: require("../../assets/images/Experience-Center/AI-ML.webp"),
      title: "AI/ML",
      subtitle:
        "Computers simulate human intelligence, learning and improving over time.",
    },
    {
      img: require("../../assets/images/Experience-Center/Drones.webp"),
      title: "Drones",
      subtitle:
        "Remote or automated unmanned aerial vehicles for data collection.",
    },
    {
      img: require("../../assets/images/Experience-Center/Electric-Vehicle.webp"),
      title: "Electric Vehicles",
      subtitle:
        "Electric motorized vehicles promoting sustainable transportation.",
    },
    {
      img: require("../../assets/images/Experience-Center/Product-Engg.webp"),
      title: "Product Engineering",
      subtitle: "Designing and testing physical products.",
    },
  ];

  const scrollRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 1.5;
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };

  return (
    <section className="common-section experience-center-section">
      <div className="container">
        <p className="horizontal-styled-span mt-lg-4 mt-4"></p>

        <div className="d-flex justify-content-between  mt-lg-3 mt-4 mb-4">
          <p className="experience-center-title">
            Experience <span style={{ fontWeight: "400" }}>Center</span>
          </p>
        </div>

        <div
          className="experience-center-images mt-lg-5 mb-lg-5"
          ref={scrollRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {experienceCenterData.map((data, ind) => (
            <div className="experience-center-item" key={ind}>
              <img
                src={data.img}
                alt={data.title}
                draggable="false"
                loading="lazy"
              />
              <div className="mt-lg-4">
                <p className="center-title mb-2">{data.title}</p>
                <p className="center-sub-title">{data.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="button-container my-auto d-flex">
          <button
            className="scroll-button prev-button btn mt-3 mt-lg-0"
            onClick={() => scroll(-200)}
          >
            <img
              src={require("../../assets/images/AboutUs/Advisor/prev-arrow.png")}
              alt="prev-arrow"
            />
          </button>
          <button
            className="scroll-button next-button btn ms-3 mt-3 mt-lg-0"
            onClick={() => scroll(200)}
          >
            <img
              src={require("../../assets/images/AboutUs/Advisor/next-arrow.png")}
              alt="prev-arrow"
            />
          </button>
        </div>
      </div>
    </section>
  );
};

export default ExperienceCenter;
