import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import newData from "../../assets/json/NewsJson.json";
import "../../assets/css/Blogs/BlogsMainPage.css"; // Ensure you have the necessary CSS

const NextNews = ({ newsName }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:991px)");

  const filteredData = Array.isArray(newData)
    ? newData.filter((data) => newsName !== data.newsTitle)
    : [];

  const navigateToFullBlog = (newsName) => {
    const formattedNewsName = newsName.replace(/ /g, "_");
    const newsCardsData = newData.find((data) => data.newsTitle === newsName);

    navigate(`/news/${formattedNewsName}`, {
      state: { newsCardsData },
    });
  };

  const chunkedData = [];
  for (let i = 0; i < filteredData.length; i += 3) {
    chunkedData.push(filteredData.slice(i, i + 3));
  }

  useEffect(() => {
    const carouselElement = document.querySelector("#newsCarousel");
    if (carouselElement) {
      new window.bootstrap.Carousel(carouselElement, {
        interval: false,
      });
    }
  }, []);

  return (
    <section className="common-section">
      {/* code for mobile view */}

      {isMobile ? (
        <>
          <p className="horizontal-styled-span mb-0"></p>
          <p className="common-title">
            What to Read <span className="common-title-span">Next</span>{" "}
          </p>
          <div className="scrollCardsWrapper">
            {filteredData.map((data, ind) => (
              <div
                className="blogCard"
                key={ind}
                onClick={() => navigateToFullBlog(data.newsTitle)}
                style={{ cursor: "pointer" }}
              >
                <img src={data.img} alt="blog-img" className="img-fluid" />
                <div className="blogCardData">
                  <p className="blogTitle">{data.newsTitle}</p>
                  <p className="blogDesc">{data.newsDesc}</p>
                  <p className="collaborationKnowMore">Know More</p>
                </div>
              </div>
            ))}
          </div>{" "}
        </>
      ) : (
        <>
          {/* code for desktop view */}

          <div>
            <p className="horizontal-styled-span mb-0"></p>
            <p className="common-title mb-4">
              What to Read <span className="common-title-span">Next</span>{" "}
            </p>

            <div id="newsCarousel" className="carousel slide">
              <div className="carousel-inner">
                {chunkedData.map((dataChunk, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    key={index}
                  >
                    <div className="row">
                      {dataChunk.map((data, ind) => (
                        <div
                          className="col-lg-4 mt-4"
                          key={ind}
                          onClick={() => navigateToFullBlog(data.newsTitle)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="card blogCard">
                            <img
                              src={data.img}
                              alt="blog-img"
                              className="blog-Img"
                            />
                            <div className="blogCardData">
                              <p className="blogTitle">{data.newsTitle}</p>
                              <p className="blogDesc">{data.newsDesc}</p>
                              <div className="row">
                                <div className="col-lg-6 col-12">
                                  <p className="blogAuthor mb-0">
                                    {data.newsAuthor}
                                  </p>
                                  <p className="blogDate">{data.date}</p>
                                </div>
                                <div className="col-lg-6 col-12 my-auto">
                                  <p className="blogReadingTime">
                                    {data.readingTime}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {filteredData.length > 3 && (
            <div className="blog-carousel-btns">
              <button
                className="carousel-control-prev innovationPrevBtn"
                type="button"
                data-bs-target="#newsCarousel"
                data-bs-slide="prev"
              >
                <img
                  src={require("../../assets/images/Innovation/prev-arrow.png")}
                  alt="prev-arrow"
                />
              </button>
              <button
                className="carousel-control-next innovationNextBtn"
                type="button"
                data-bs-target="#newsCarousel"
                data-bs-slide="next"
              >
                <img
                  src={require("../../assets/images/Innovation/next-arrow.png")}
                  alt="next-arrow"
                />
              </button>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default NextNews;
