import React from "react";
import "../../assets/css/Career/CareerForm.css";
import { Link, useLocation } from "react-router-dom";
import CareerForm from "./CareerForm";

const CareerFormDetails = () => {
  const breadcrumbData = [
    {
      title: "Home /",
      to: "/",
      status: "prev",
    },
    {
      title: "Careers",
      to: "/career-form",
      status: "current",
    },
  ];
  const location = useLocation();
  const data = location?.state;

  return (
    <section className="common-section career-form-details-section">
      <div className="container">
        {breadcrumbData.map((data, ind) => {
          return (
            <Link
              to={data?.to}
              className={
                data.status === "current"
                  ? "careerActiveBreadCrumb"
                  : "careerPrevBreadCrumb"
              }
              key={ind}
            >
              {data?.title}
            </Link>
          );
        })}
        <div className="form-details">
          <div className="row">
            <div className="col-lg-8 col-12">
              <p className="job-title">{data?.title}</p>
            </div>
            {/* <div className="col-lg-4 col-4">
              <p className="posted-on"></p>
            </div> */}
          </div>

          <div className="row">
            <div className="col-lg-6 col-12 order-lg-1 order-2 mt-lg-0 mt-5">
              <p className="job-desc-title">About this job</p>
              <p className="about-desc">{data?.description}</p>
              <p className="job-desc-title">Responsibilities</p>
              <ul className="responsibilityAndRequirements">
                {data?.responsibilities.map((data, ind) => {
                  return <li key={ind}>{data}</li>;
                })}
              </ul>
              <p className="job-desc-title">Skills Requirement</p>
              <ul className="responsibilityAndRequirements">
                {data?.skills.map((data, ind) => {
                  return <li key={ind}>{data}</li>;
                })}
              </ul>
              <p className="job-desc-title">Qualification</p>
              <p className="responsibilityAndRequirements">
                <ul style={{ listStyleType: "none" }}>
                  <li className="">{data?.qualification}</li>
                </ul>
              </p>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-1">
              {/* career form component */}
              <CareerForm post={data?.title} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareerFormDetails;
