import "../assets/css/SchoolAssociated.css";
import ArmySchool from "../assets/images/SchoolsAssociated/Army-Public_school_Icon.webp";
import AngelSchool from "../assets/images/SchoolsAssociated/AngelSchool-Icon.webp";
import HorizonSchool from "../assets/images/SchoolsAssociated/Horizon-Icon.webp";
import IndianModelInternational from "../assets/images/SchoolsAssociated/IMMS-Icon.webp";
import PriyaDarshniSchool from "../assets/images/SchoolsAssociated/Priyadarshani-Icon.webp";
import ShriRamSchool from "../assets/images/SchoolsAssociated/TSUS-Icon.webp";
import RavishankarVidyaMandir from "../assets/images/SchoolsAssociated/SSRVM-Icon.webp";
import YSPM from "../assets/images/SchoolsAssociated/YSPM-Icon.webp";
import ButtonStructure from "../components/ButtonStructure";

const SchoolsAssociated = () => {
  const schoolsData = [
    {
      id: 1,
      schoolImg: ArmySchool,
    },
    {
      id: 2,
      schoolImg: RavishankarVidyaMandir,
    },
    {
      id: 3,
      schoolImg: PriyaDarshniSchool,
    },
    {
      id: 4,
      schoolImg: IndianModelInternational,
    },
    {
      id: 5,
      schoolImg: ShriRamSchool,
    },
    {
      id: 6,
      schoolImg: HorizonSchool,
    },
    {
      id: 7,
      schoolImg: YSPM,
    },
    {
      id: 8,
      schoolImg: AngelSchool,
    },
  ];

  return (
    <section className="schools-associated-section">
      <div className="container">
        <p className="horizontal-styled-span"></p>
        <p className="common-title mtlg-3 mt-3">
          Our <span className="common-title-span">Clients</span>
        </p>

        <div className="schools-logo-wrapper">
          <div className="row schools-card">
            {schoolsData?.map((data) => (
              <div
                className=" school-logo-col col-lg-3 col-md-3 col-sm-4 col-4 text-center mb-5 "
                key={data.id}
              >
                <img
                  src={data.schoolImg}
                  alt="school logo"
                  className="school-associated-logo m-auto"
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          {/* button structure component */}
          <ButtonStructure
            btnName="View All"
            NameOfClass="schoolAssociatedBtn"
            to="/our-clients"
          />
        </div>
      </div>
    </section>
  );
};

export default SchoolsAssociated;
