import React from "react";
import { Link, useLocation } from "react-router-dom";
import WhyRoboticsLab from "../HomePages/WhyRoboticsLab";
import NextBlog from "./NextBlog";

const BlogDetails = () => {
  const location = useLocation();
  const blogData = location.state.blogData;
  const activeChip = location.state.activeChip;

  return (
    <section className="common-section mt-5">
      <div className="container mt-4">
        <div className="d-flex">
          <Link
            to="/"
            className="prevBreadCrumb"
            style={{ color: "rgba(0, 0, 0, 1)" }}
          >
            Home /
          </Link>
          <Link
            to="/blogs"
            className="activeBreadCrumb"
            style={{ color: "rgba(0, 0, 0, 1)" }}
          >
            Blogs
          </Link>
        </div>

        <div className="mt-4">
          <div className="d-flex">
            <p className="blogAuthor">{blogData.blogAuthor}</p>{" "}
            <span className="vertical-line"></span>
            <p className="blogDate">{blogData.date}</p>
          </div>

          <img src={blogData.img} alt="blog-img" className="img-fluid" />
          <p className="blogTitle mt-5">{blogData.blogTitle}</p>
          <p className="blogDesc mt-4">{blogData.blogFullDesc}</p>
        </div>

        {/* what to next read blogs component */}
        <NextBlog blogName={blogData.blogTitle} activeChip={activeChip} />
      </div>

      <WhyRoboticsLab />
    </section>
  );
};

export default BlogDetails;
