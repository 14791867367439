// src/components/AboutUsComponents/AboutVisionMissionStructure.js
import "../../assets/css/Shimmer.css";
import React, { useState, useEffect, useRef } from "react";
import ShimmerEffect from "../ShimmerEffect";
import ImageDisplay from "../ImageDisplay";

const AboutVisionMissionStructure = ({ data }) => {
  const isTextLeft = data?.layout === "text-left";

  return (
    <section className="mt-lg-5 mt-4">
      <div className="container">
        <div className="row">
          {isTextLeft ? (
            <>
              <div className="col-lg-6 col-md-12 col-12  order-lg-1 order-2  mt-lg-0 mt-3">
                <p className="about-desc">{data?.content[0]}</p>
              </div>
              <div className="col-lg-6 col-md-12 col-12 m-auto order-lg-2 order-1">
                <ShimmerEffect
                  src={data?.content[1]}
                  alt="img"
                  shimmerHeight="400"
                  shimmerWeight="600"
                  style={{ borderRadius: "24px" }}
                />
                {/* <img src={data?.content[1]} alt="img" className="img-fluid" style={{borderRadius:'24px'}} loading="lazy"/> */}
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-6 col-md-12 col-12 m-auto">
                <ImageDisplay src={data?.content[1]} alt="img" />
                {/* <img src={data?.content[1]} alt="img" className="img-fluid" loading="lazy"/> */}
              </div>
              <div className="col-lg-6 col-md-12 col-12  mt-lg-0 mt-3">
                {" "}
                <p className="about-desc">{data?.content[0]}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default AboutVisionMissionStructure;
