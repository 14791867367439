import React from "react";
import BreadCrumb from "./BreadCrumb";

const CommonHeader = ({
  breadcrumbData,
  mainTitle,
  subTitle,
  classNameOfCommonHeader,
}) => {
  return (
    <section className={`common-header ${classNameOfCommonHeader}`}>
      <div className="container">
        <div className="row">
          <BreadCrumb breadcrumbData={breadcrumbData} />
          <p className="about-us-main-title mb-0">{mainTitle}</p>
          <p className="about-us-sub-title mt-2">{subTitle}</p>
        </div>
      </div>
    </section>
  );
};

export default CommonHeader;
