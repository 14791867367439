import React from "react";
import BelopLogo from "../assets/images/OurPartners/BelopLogo.svg";
import EdcilLogo from "../assets/images/OurPartners/EdcilLogo.svg";
import CitiLogo from "../assets/images/OurPartners/CitiLogo.svg";
import TcilLogo from "../assets/images/OurPartners/TCILLogo.svg";
import BecilLogo from "../assets/images/OurPartners/BecilLogo.svg";
import ImageTextGrid from "../components/ImageTextGrid";
import "../assets/css/OurPartners.css";

const OurPartners = () => {
  const items = [
    { image: BelopLogo },
    { image: EdcilLogo },
    { image: CitiLogo },
    { image: TcilLogo },
    { image: BecilLogo },
  ];
  return (
    <section className="Our-Partners-section common-section">
      <div className="container">
        <div>
          <p className="horizontal-styled-span mb-1"></p>
          <p className="Our-Partners-header">
            Our <span className="common-title-span">Partners</span>{" "}
          </p>
        </div>
        <ImageTextGrid
          items={items}
          columnsLargeScreen={3}
          columnSmallScreen={4}
          columnExtraSmallScreen={4}
          divCss="OurPartners-imageGrid"
        />
      </div>
    </section>
  );
};

export default OurPartners;
