import React from "react";

const OurMilestone = () => {
  return (
    <section className="our-milestone-section">
      <div className="container">
        <p className="horizontal-styled-span mt-lg-4 mb-1"></p>
        <p className="common-title">
          Our <span style={{ fontWeight: "700" }}>Milestone</span>
        </p>
        <div className="row">
          <div className="col-lg-6 col-12">
            <p className="milestone-title">
              PHN Technology has charted a remarkable journey over the years.
            </p>
            <ul>
              <li>
                In 2015, conducted software development workshops across Pune,
                Kolhapur, and Mumbai, reaching over 100 college.
              </li>
              <li>
                By 2017, PHN Technology introduced IoT and robotics programs
                across Maharashtra, impacting over 400 colleges and 100 schools.
              </li>
              <li>
                In 2020, launched online platforms for robotics and advanced
                technology across India, reaching over 1,000 colleges and
                100,000 students.
              </li>
              <li>
                By 2023, PHN Technology initiated edtech products and accessible
                learning for all, targeting 200+ clients and 2Lac+ users across
                the Asia Pacific Region (APAC) and Middle East.
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-12 m-auto">
            <div className="card p-lg-5 p-3 m-auto border-0">
              <div className="row">
                <div className="col-6 border-end border-bottom">
                  <p className="count-title mb-0 text-center">5+</p>
                  <p className="count-desc text-center mb-4">Awards Received</p>
                </div>
                <div className="col-6 border-bottom">
                  <p className="count-title mb-0 text-center ">300+</p>
                  <p className="count-desc text-center">Expert Trainers</p>
                </div>
                <div className="col-6 border-end">
                  <p className="count-title mb-0 text-center mt-4">3000+</p>
                  <p className="count-desc text-center">Schools Connected</p>
                </div>
                <div className="col-6">
                  <p className="count-title mb-0 text-center  mt-4">2Lac+</p>
                  <p className="count-desc text-center">Students Trained</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurMilestone;
